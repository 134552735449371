import {
  addDoc,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  getDoc,
  increment
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { httpsCallable, HttpsCallableResult, Functions } from 'firebase/functions';
import { walletsCollection } from '../lib/collections';
import { Wallet } from '../types';
import { functions } from '../lib/firebase';

type WalletRole = 'owner' | 'editor' | 'viewer';

export const createWallet = async (userId: string, data: Omit<Wallet, 'id'>): Promise<string> => {
  const walletData = {
    ...data,
    userId,
    members: {},
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };

  const docRef = await addDoc(walletsCollection, walletData);
  return docRef.id;
};

export const getUserWallets = async (userId: string): Promise<Wallet[]> => {
  // Get wallets where user is owner
  const ownerQuery = query(
    walletsCollection,
    where('userId', '==', userId)
  );

  // Get wallets where user is a member with any role
  const memberQuery = query(
    walletsCollection,
    where(`members.${userId}.role`, 'in', ['owner', 'editor', 'viewer'])
  );

  const [ownerSnapshot, memberSnapshot] = await Promise.all([
    getDocs(ownerQuery),
    getDocs(memberQuery)
  ]);

  const ownerWallets = ownerSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as Wallet));

  const memberWallets = memberSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as Wallet));

  // Combine and deduplicate wallets
  const walletMap = new Map();
  [...ownerWallets, ...memberWallets].forEach(wallet => {
    walletMap.set(wallet.id, wallet);
  });

  return Array.from(walletMap.values());
};

export const updateWallet = async (walletId: string, data: Partial<Wallet>): Promise<void> => {
  const walletRef = doc(walletsCollection, walletId);
  const walletDoc = await getDoc(walletRef);
  
  if (!walletDoc.exists()) {
    throw new Error('Wallet not found');
  }

  // Preserve existing members and userId
  const currentData = walletDoc.data();
  await updateDoc(walletRef, {
    ...data,
    userId: currentData.userId, // Preserve the original owner
    members: currentData.members || {}, // Preserve existing members
    updatedAt: new Date().toISOString()
  });
};

export const deleteWallet = async (walletId: string): Promise<void> => {
  await deleteDoc(doc(walletsCollection, walletId));
};

export const shareWallet = async (
  walletId: string,
  email: string,
  role: WalletRole
): Promise<void> => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  
  if (!currentUser) {
    throw new Error('User must be authenticated');
  }

  const handleWalletInvitation = httpsCallable<
    { walletId: string; email: string; role: WalletRole; inviterId: string },
    { success: boolean; invitationId: string; error?: string }
  >(functions, 'handleWalletInvitation');
  
  try {
    const result = await handleWalletInvitation({
      walletId,
      email,
      role,
      inviterId: currentUser.uid
    });

    if (!result.data.success) {
      throw new Error(result.data.error || 'Failed to send invitation');
    }
  } catch (error) {
    console.error('Error sending wallet invitation:', error);
    if (error instanceof Error) {
      // Check if the error has additional details in its message or code
      const message = (error as { details?: string }).details || error.message;
      throw new Error(message);
    }
    throw new Error('Failed to send invitation');
  }
};

export const acceptInvitation = async (
  invitationId: string,
  userId: string
): Promise<void> => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  
  if (!currentUser) {
    throw new Error('User must be authenticated');
  }

  const handleWalletInvitation = httpsCallable<
    { invitationId: string; userId: string; action: 'accept' },
    { success: boolean; error?: string }
  >(functions, 'handleWalletInvitation');
  
  try {
    const result = await handleWalletInvitation({
      invitationId,
      userId,
      action: 'accept'
    });

    if (!result.data.success) {
      throw new Error(result.data.error || 'Failed to accept invitation');
    }
  } catch (error) {
    console.error('Error accepting wallet invitation:', error);
    if (error instanceof Error) {
      // Check if the error has additional details in its message or code
      const message = (error as { details?: string }).details || error.message;
      throw new Error(message);
    }
    throw new Error('Failed to accept invitation');
  }
};

export const removeMember = async (walletId: string, userId: string): Promise<void> => {
  const walletRef = doc(walletsCollection, walletId);
  const walletDoc = await getDoc(walletRef);
  
  if (!walletDoc.exists()) {
    throw new Error('Wallet not found');
  }

  const currentData = walletDoc.data();
  const currentMembers = { ...currentData.members };
  delete currentMembers[userId];

  await updateDoc(walletRef, {
    members: currentMembers
  });
};

export const updateWalletBalance = async (walletId: string, amount: number): Promise<void> => {
  const walletRef = doc(walletsCollection, walletId);
  const walletDoc = await getDoc(walletRef);
  
  if (!walletDoc.exists()) {
    throw new Error('Wallet not found');
  }

  await updateDoc(walletRef, {
    balance: increment(amount),
    updatedAt: new Date().toISOString()
  });
};

export const updateWalletBalanceForTransaction = async (
  walletId: string,
  oldAmount: number | null,
  newAmount: number
): Promise<void> => {
  // If there's an old amount, we need to reverse it first
  if (oldAmount !== null) {
    await updateWalletBalance(walletId, -oldAmount);
  }
  
  // Then add the new amount
  await updateWalletBalance(walletId, newAmount);
};
