export const inviteMembersPage = {
  title: 'Üye Davet Et',
  subtitle: 'Cüzdanlarınızı aile, arkadaş veya iş arkadaşlarınızla paylaşın',
  form: {
    email: {
      label: 'E-posta adresi',
      placeholder: 'ornek@example.com'
    },
    accessLevel: {
      label: 'Erişim seviyesi',
      description: 'Düzenleyiciler işlem ekleyebilir, düzenleyebilir ve silebilir. Görüntüleyiciler sadece işlemleri görüntüleyebilir.',
      options: {
        editor: 'Düzenleyebilir',
        viewer: 'Sadece görüntüleyebilir'
      }
    },
    wallets: {
      label: 'Paylaşılacak cüzdanları seçin',
      selectAtLeastOne: 'Lütfen en az bir cüzdan seçin'
    },
    submit: {
      label: 'Davetleri gönder',
      sending: 'Davetler gönderiliyor...'
    }
  },
  messages: {
    success: 'Davetler başarıyla gönderildi!',
    invitationCancelled: 'Davet başarıyla iptal edildi',
    error: {
      selectWallet: 'Lütfen en az bir cüzdan seçin',
      sendFailed: 'Davetler gönderilemedi',
      alreadyInvited: 'Bu cüzdan için zaten bekleyen bir davet var',
      cancelFailed: 'Davet iptal edilemedi',
      partialSuccess: 'Bazı davetler gönderilemedi. Detaylar için bekleyen davetler listesini kontrol edin.'
    }
  },
  pendingInvitations: {
    title: 'Bekleyen Davetler',
    details: '{date} tarihinde {wallet} cüzdanına {role} olarak davet edildi',
    empty: 'Bekleyen davet yok',
    role: {
      editor: 'düzenleyici',
      viewer: 'görüntüleyici'
    }
  },
  permissions: {
    viewer: 'Görüntüleyici',
    editor: 'Düzenleyici',
    admin: 'Yönetici'
  },
  validation: {
    emailRequired: 'E-posta adresi gerekli',
    emailInvalid: 'Lütfen geçerli bir e-posta adresi girin',
    maxInvitesReached: 'Maksimum davet sayısına ulaşıldı'
  },
  status: {
    pending: 'Bekliyor',
    accepted: 'Kabul Edildi',
    declined: 'Reddedildi',
    cancelled: 'İptal Edildi',
    failed: 'Başarısız'
  },
  actions: {
    resend: 'Daveti Yeniden Gönder',
    cancel: 'Daveti İptal Et'
  },
  errors: {
    inviteFailed: 'Davet gönderilemedi',
    alreadyMember: 'Bu kullanıcı zaten üye',
    alreadyInvited: 'Bu e-posta adresine zaten bir davet gönderildi'
  }
};
