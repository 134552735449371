const translations = {
  errors: {
    loadFailed: 'Cüzdanlar yüklenemedi',
    createFailed: 'Cüzdan oluşturulamadı',
    updateFailed: 'Cüzdan güncellenemedi',
    deleteFailed: 'Cüzdan silinemedi',
    shareFailed: 'Davet gönderilemedi',
    removeMemberFailed: 'Üye kaldırılamadı'
  },
  success: {
    created: 'Cüzdan başarıyla oluşturuldu',
    updated: 'Cüzdan başarıyla güncellendi',
    deleted: 'Cüzdan başarıyla silindi',
    shared: 'Davet başarıyla gönderildi',
    memberRemoved: 'Üye başarıyla kaldırıldı'
  },
  allWallets: 'Tüm Cüzdanlar'
};

export default translations;
