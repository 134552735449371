import { ref, uploadBytes, getDownloadURL, deleteObject, listAll, StorageReference } from 'firebase/storage';
import { storage } from '../lib/firebase';

export const uploadProfilePhoto = async (userId: string, file: File): Promise<string> => {
  try {
    // Create a reference to the profile photo location
    // Use timestamp to ensure unique filename and prevent caching issues
    const timestamp = Date.now();
    const filename = `${timestamp}-${file.name}`;
    const photoRef = ref(storage, `users/${userId}/avatar/${filename}`);

    // Upload the file
    await uploadBytes(photoRef, file);

    // Get the download URL with cache busting parameter
    const downloadURL = await getDownloadURL(photoRef);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading profile photo:', error);
    throw error;
  }
};

export const deleteProfilePhoto = async (userId: string): Promise<void> => {
  try {
    // List all files in the avatar directory
    const photoRef = ref(storage, `users/${userId}/avatar`);
    const list = await listAll(photoRef);
    
    // Delete each file
    await Promise.all(list.items.map((item: StorageReference) => deleteObject(item)));
  } catch (error) {
    // Ignore if file doesn't exist
    if ((error as any)?.code !== 'storage/object-not-found') {
      console.error('Error deleting profile photo:', error);
      throw error;
    }
  }
};

export const uploadTransactionAttachment = async (userId: string, transactionId: string, file: File): Promise<string> => {
  try {
    // Create a unique filename using timestamp
    const timestamp = Date.now();
    const filename = `${timestamp}-${file.name}`;
    
    // Create a reference to the attachment location
    const attachmentRef = ref(storage, `users/${userId}/transactions/${transactionId}/${filename}`);

    // Upload the file
    await uploadBytes(attachmentRef, file);

    // Get the download URL
    const downloadURL = await getDownloadURL(attachmentRef);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading transaction attachment:', error);
    throw error;
  }
};

export const deleteTransactionAttachment = async (url: string): Promise<void> => {
  try {
    // Get the reference from the URL
    const attachmentRef = ref(storage, url);
    await deleteObject(attachmentRef);
  } catch (error) {
    // Ignore if file doesn't exist
    if ((error as any)?.code !== 'storage/object-not-found') {
      console.error('Error deleting transaction attachment:', error);
      throw error;
    }
  }
};
