export const creditCardsPage = {
  title: 'Credit Cards',
  subtitle: 'Manage your credit cards and track balances',
  new: 'New Credit Card',
  noCreditCards: 'No credit cards found',
  addCreditCardButton: 'Add Credit Card',
  totalLimit: 'Total Limit',
  availableLimit: 'Available Limit',
  usedLimit: 'Used Limit',
  filters: {
    status: 'Status',
    bank: 'Bank',
    type: 'Card Type'
  },
  statuses: {
    active: 'Active',
    blocked: 'Blocked',
    expired: 'Expired'
  },
  cardTypes: {
    credit: 'Credit Card',
    debit: 'Debit Card',
    prepaid: 'Prepaid Card'
  },
  fields: {
    cardNumber: 'Card Number',
    validThru: 'Valid Thru',
    expiryDate: 'Expiry Date',
    limit: 'Limit',
    balance: 'Balance',
    available: 'Available'
  }
};
