import { sidebar } from './components/sidebar/en';
import { reportsPage } from './pages/reportsPage/en';
import { subscriptionCard } from './components/subscriptionCard/en';
import notifications from './pages/settings/notifications/en';

const translations = {
  common: {
    loading: 'Loading...',
    error: 'An error occurred',
    success: 'Success',
    save: 'Save',
    saving: 'Saving...',
    cancel: 'Cancel',
    delete: 'Delete',
    edit: 'Edit',
    add: 'Add',
    search: 'Search',
    filter: 'Filter',
    sort: 'Sort',
    actions: 'Actions',
    status: 'Status',
    details: 'Details',
    settings: 'Settings',
    logout: 'Logout',
    profile: 'Profile',
    notifications: 'Notifications',
    help: 'Help',
    about: 'About',
    freePlan: 'Free Plan',
    premiumPlan: 'Premium Plan',
    confirmDelete: 'Are you sure you want to delete this?',
    optional: 'optional',
    more: 'more',
    selectAll: 'Select All',
    currency: {
      usd: 'USD',
      eur: 'EUR',
      gbp: 'GBP',
      try: 'TRY'
    },
    colors: {
      indigo: 'Indigo',
      emerald: 'Emerald',
      amber: 'Amber'
    }
  },
  components: {
    sidebar,
    subscriptionCard
  },
  pages: {
    reportsPage,
    settings: {
      notifications
    }
  }
};

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export type TranslationKeys = typeof translations;
export type Translations = DeepPartial<TranslationKeys>;
export default translations;
