import { BudgetModalTranslations } from './types';

export const translations: BudgetModalTranslations = {
  title: {
    new: 'Add New Budget',
    edit: 'Edit Budget'
  },
  sections: {
    generalInfo: {
      title: 'General Info',
      fields: {
        name: {
          label: 'Budget Name',
          placeholder: 'Enter budget name'
        },
        amount: {
          label: 'Amount',
          placeholder: 'Enter budget amount'
        },
        currency: {
          label: 'Currency'
        }
      }
    },
    filter: {
      title: 'Budget Filter',
      fields: {
        wallets: {
          label: 'Wallets',
          allWallets: 'All wallets'
        },
        categories: {
          label: 'Categories',
          allCategories: 'All categories'
        }
      }
    },
    period: {
      title: 'Budget Period',
      fields: {
        recurrence: {
          label: 'Recurrence',
          options: {
            once: 'Once',
            daily: 'Daily',
            weekly: 'Weekly',
            biweekly: 'Biweekly',
            monthly: 'Monthly',
            yearly: 'Yearly'
          }
        },
        startDate: {
          label: 'Start date',
          placeholder: 'Select start date'
        },
        endDate: {
          label: 'End date',
          placeholder: 'Select end date'
        }
      }
    }
  },
  buttons: {
    cancel: 'Cancel',
    save: 'Save',
    create: 'Create'
  },
  tooltips: {
    name: 'Give your budget a descriptive name',
    amount: 'Set the total budget amount',
    wallets: 'Select which wallets to include in this budget',
    categories: 'Select which categories to track in this budget',
    recurrence: 'Choose how often this budget repeats',
    startDate: 'When should this budget start?'
  }
};
