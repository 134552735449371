const translations = {
  title: 'Transactions',
  subtitle: 'Manage your transactions',
  new: 'New Transaction',
  types: {
    all: 'All Types',
    income: 'Income',
    expense: 'Expense'
  },
  categories: {
    all: 'All Categories',
    income: 'Income Categories',
    expense: 'Expense Categories'
  },
  labels: {
    all: 'All Labels',
    filter: 'Filter by Label'
  },
  status: {
    all: 'All Status',
    completed: 'Completed',
    pending: 'Pending',
    cancelled: 'Cancelled'
  },
  sortOrder: 'Sort Order',
  installments: {
    format: '{{current}} of {{total}}',
    periods: {
      monthly: 'Monthly',
      weekly: 'Weekly',
      yearly: 'Yearly'
    }
  },
  fields: {
    description: 'Description',
    amount: 'Amount',
    date: 'Date',
    category: 'Category',
    wallet: 'Wallet',
    labels: 'Labels',
    attachments: 'Attachments',
    notes: 'Notes',
    creditCard: 'Credit Card',
    paymentMethod: 'Payment Method',
    status: 'Status'
  },
  filters: {
    title: 'Filters',
    type: 'Transaction Type',
    category: 'Category',
    label: 'Label',
    wallet: 'Wallet'
  },
  noTransactions: 'No transactions found'
};

export default translations;
