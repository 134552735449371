const translations = {
  loading: 'Yükleniyor...',
  error: 'Hata',
  success: 'Başarılı',
  cancel: 'İptal',
  save: 'Kaydet',
  delete: 'Sil',
  edit: 'Düzenle',
  create: 'Oluştur',
  update: 'Güncelle',
  submit: 'Gönder',
  confirm: 'Onayla',
  selectAll: 'Tümünü Seç',
  deselectAll: 'Seçimi Kaldır',
  search: 'Ara',
  filter: 'Filtrele',
  sort: 'Sırala',
  actions: 'İşlemler',
  status: 'Durum',
  type: 'Tür',
  date: 'Tarih',
  amount: 'Tutar',
  description: 'Açıklama',
  category: 'Kategori',
  categories: 'Kategoriler',
  name: 'İsim',
  email: 'E-posta',
  password: 'Şifre',
  currency: 'Para Birimi',
  balance: 'Bakiye',
  total: 'Toplam',
  settings: 'Ayarlar',
  profile: 'Profil',
  logout: 'Çıkış Yap',
  back: 'Geri',
  close: 'Kapat',
  add: 'Ekle',
  remove: 'Kaldır',
  yes: 'Evet',
  no: 'Hayır',
  or: 'veya',
  and: 've',
  all: 'Tümü',
  none: 'Hiçbiri',
  other: 'Diğer',
  validation: {
    required: 'Zorunlu',
    optional: 'İsteğe Bağlı'
  }
};

export default translations;
