import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useLanguage } from './LanguageContext';
import { useNotification } from './NotificationContext';
import { Wallet, WalletMember } from '../types';
import * as walletService from '../services/wallets';
import { getTranslations } from '../locales/wallets';

interface WalletContextType {
  wallets: Wallet[];
  isLoading: boolean;
  error: string | null;
  addWallet: (wallet: Omit<Wallet, 'id'>) => Promise<void>;
  editWallet: (id: string, wallet: Partial<Wallet>) => Promise<void>;
  deleteWallet: (id: string) => Promise<void>;
  shareWallet: (walletId: string, email: string, role: WalletMember['role']) => Promise<void>;
  removeMember: (walletId: string, memberId: string) => Promise<void>;
  refreshWallets: () => Promise<void>;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const useWallets = () => {
  const context = useContext(WalletContext);
  if (!context) throw new Error('useWallets must be used within WalletProvider');
  return context;
};

export const WalletProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const { showNotification } = useNotification();
  const translations = getTranslations(language);
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const loadWallets = async () => {
    if (!user) {
      setWallets([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      const userWallets = await walletService.getUserWallets(user.id);
      setWallets(userWallets);
    } catch (err) {
      console.error('Error loading wallets:', err);
      setError(err instanceof Error ? err.message : translations.errors.loadFailed);
      showNotification('error', translations.errors.loadFailed);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadWallets();
  }, [user]);

  const addWallet = async (data: Omit<Wallet, 'id'>) => {
    if (!user) return;
    setIsLoading(true);
    setError(null);

    try {
      const walletId = await walletService.createWallet(user.id, data);
      const newWallet = { ...data, id: walletId };
      setWallets(current => [...current, newWallet]);
      showNotification('success', translations.success.created);
    } catch (err) {
      setError(err instanceof Error ? err.message : translations.errors.createFailed);
      showNotification('error', translations.errors.createFailed);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const editWallet = async (id: string, updates: Partial<Wallet>) => {
    setIsLoading(true);
    setError(null);

    try {
      await walletService.updateWallet(id, updates);
      setWallets(current =>
        current.map(wallet =>
          wallet.id === id ? { ...wallet, ...updates } : wallet
        )
      );
      showNotification('success', translations.success.updated);
    } catch (err) {
      setError(err instanceof Error ? err.message : translations.errors.updateFailed);
      showNotification('error', translations.errors.updateFailed);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteWallet = async (id: string) => {
    setIsLoading(true);
    setError(null);

    try {
      await walletService.deleteWallet(id);
      setWallets(current => current.filter(wallet => wallet.id !== id));
      showNotification('success', translations.success.deleted);
    } catch (err) {
      setError(err instanceof Error ? err.message : translations.errors.deleteFailed);
      showNotification('error', translations.errors.deleteFailed);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const shareWallet = async (walletId: string, email: string, role: WalletMember['role']) => {
    if (!user) return;
    setIsLoading(true);
    setError(null);

    try {
      await walletService.shareWallet(walletId, email, role);
      // Update local state
      setWallets(current =>
        current.map(wallet => {
          if (wallet.id === walletId) {
            const members = wallet.members || {};
            return {
              ...wallet,
              members: {
                ...members,
                [email]: {
                  role,
                  joinedAt: new Date().toISOString()
                }
              }
            };
          }
          return wallet;
        })
      );
      showNotification('success', translations.success.shared);
    } catch (err) {
      setError(err instanceof Error ? err.message : translations.errors.shareFailed);
      showNotification('error', translations.errors.shareFailed);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const removeMember = async (walletId: string, memberId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      await walletService.removeMember(walletId, memberId);
      // Update local state
      setWallets(current =>
        current.map(wallet => {
          if (wallet.id === walletId) {
            const members = { ...wallet.members };
            if (members) {
              delete members[memberId];
            }
            return { ...wallet, members };
          }
          return wallet;
        })
      );
      showNotification('success', translations.success.memberRemoved);
    } catch (err) {
      setError(err instanceof Error ? err.message : translations.errors.removeMemberFailed);
      showNotification('error', translations.errors.removeMemberFailed);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const refreshWallets = async () => {
    await loadWallets();
  };

  return (
    <WalletContext.Provider value={{
      wallets,
      isLoading,
      error,
      addWallet,
      editWallet,
      deleteWallet,
      shareWallet,
      removeMember,
      refreshWallets
    }}>
      {children}
    </WalletContext.Provider>
  );
};
