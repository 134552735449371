import React, { createContext, useContext, useState } from 'react';
import { Language } from '../types/language';
import { Label } from '../types/label';

interface Settings {
  siteName: string;
  siteDescription: string;
  defaultCurrency: string;
  defaultLanguage: string;
  smtpHost: string;
  smtpPort: number;
  smtpUsername: string;
  smtpPassword: string;
  fromEmail: string;
  fromName: string;
  apiUrl: string;
  uploadSizeLimit: number;
  allowedFileTypes: string;
  notifications: {
    email: { [key: string]: boolean };
    push: { [key: string]: boolean };
    inApp: { [key: string]: boolean };
  };
  security: {
    passwordRequirements: {
      minLength: boolean;
      requireUppercase: boolean;
      requireLowercase: boolean;
      requireNumbers: boolean;
      requireSpecialChars: boolean;
    };
    sessionTimeout: number;
    maxLoginAttempts: number;
  };
}

interface AdminContextType {
  settings: Settings;
  updateSettings: (updates: Partial<Settings>) => void;
  stats: {
    totalUsers: number;
    activeUsers: number;
    totalRevenue: number;
    activeSubscriptions: number;
  };
  languages: Language[];
  addLanguage: (language: Omit<Language, 'lastUpdated'>) => Promise<void>;
  updateLanguage: (code: string, updates: Partial<Language>) => Promise<void>;
  deleteLanguage: (code: string) => Promise<void>;
  labels: Label[];
  addLabel: (label: Omit<Label, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  deleteLabel: (id: string) => Promise<void>;
}

const defaultSettings: Settings = {
  siteName: 'CostCaddy',
  siteDescription: 'Personal Finance Management System',
  defaultCurrency: 'USD',
  defaultLanguage: 'en',
  smtpHost: '',
  smtpPort: 587,
  smtpUsername: '',
  smtpPassword: '',
  fromEmail: 'noreply@costcaddy.com',
  fromName: 'CostCaddy',
  apiUrl: '',
  uploadSizeLimit: 10,
  allowedFileTypes: 'jpg,png,pdf',
  notifications: {
    email: {
      welcome: true,
      transaction: true,
      subscription: true,
      security: true
    },
    push: {
      welcome: true,
      transaction: true,
      subscription: true,
      security: true
    },
    inApp: {
      welcome: true,
      transaction: true,
      subscription: true,
      security: true
    }
  },
  security: {
    passwordRequirements: {
      minLength: true,
      requireUppercase: true,
      requireLowercase: true,
      requireNumbers: true,
      requireSpecialChars: true
    },
    sessionTimeout: 30,
    maxLoginAttempts: 5
  }
};

const defaultLanguages: Language[] = [
  {
    code: 'en',
    name: 'English',
    flag: '🇺🇸',
    isActive: true,
    translations: 100,
    lastUpdated: new Date().toISOString()
  },
  {
    code: 'tr',
    name: 'Turkish',
    flag: '🇹🇷',
    isActive: true,
    translations: 100,
    lastUpdated: new Date().toISOString()
  }
];

const defaultLabels: Label[] = [
  {
    id: 'essential',
    name: 'Essential',
    color: 'indigo',
    userId: 'system',
    transactionCount: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  },
  {
    id: 'recurring',
    name: 'Recurring',
    color: 'emerald',
    userId: 'system',
    transactionCount: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  }
];

const AdminContext = createContext<AdminContextType | undefined>(undefined);

export const useAdmin = () => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('useAdmin must be used within AdminProvider');
  }
  return context;
};

export const AdminProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [settings, setSettings] = useState<Settings>(defaultSettings);
  const [languages, setLanguages] = useState<Language[]>(defaultLanguages);
  const [labels, setLabels] = useState<Label[]>(defaultLabels);
  const [stats] = useState({
    totalUsers: 1250,
    activeUsers: 850,
    totalRevenue: 25000,
    activeSubscriptions: 450
  });

  const updateSettings = (updates: Partial<Settings>) => {
    setSettings(current => ({
      ...current,
      ...updates
    }));
  };

  const addLanguage = async (language: Omit<Language, 'lastUpdated'>) => {
    const newLanguage: Language = {
      ...language,
      lastUpdated: new Date().toISOString()
    };
    setLanguages(current => [...current, newLanguage]);
  };

  const updateLanguage = async (code: string, updates: Partial<Language>) => {
    setLanguages(current =>
      current.map(lang =>
        lang.code === code
          ? { ...lang, ...updates, lastUpdated: new Date().toISOString() }
          : lang
      )
    );
  };

  const deleteLanguage = async (code: string) => {
    setLanguages(current => current.filter(lang => lang.code !== code));
  };

  const addLabel = async (label: Omit<Label, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
    const timestamp = new Date().toISOString();
    const newLabel: Label = {
      ...label,
      id: `label-${Date.now()}`,
      userId: 'system',
      createdAt: timestamp,
      updatedAt: timestamp
    };
    setLabels(current => [...current, newLabel]);
  };

  const deleteLabel = async (id: string) => {
    setLabels(current => current.filter(label => label.id !== id));
  };

  return (
    <AdminContext.Provider value={{
      settings,
      updateSettings,
      stats,
      languages,
      addLanguage,
      updateLanguage,
      deleteLanguage,
      labels,
      addLabel,
      deleteLabel
    }}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
