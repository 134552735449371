import { collection, doc, writeBatch } from 'firebase/firestore';
import {
  setDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  addDoc
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Category } from '../types';
import { initialDefaultCategories, SYSTEM_USER_ID } from '../data/defaultCategories';

export const getDefaultCategories = async () => {
  try {
    const defaultCategoriesRef = collection(db, 'default_categories');
    const snapshot = await getDocs(defaultCategoriesRef);
    
    if (snapshot.empty) {
      console.log('No default categories found. Initializing...');
      return await initializeDefaultCategories();
    }

    return snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    } as Category));
  } catch (error) {
    console.error('Error getting default categories:', error);
    throw error;
  }
};

export const initializeDefaultCategories = async () => {
  try {
    const defaultCategoriesRef = collection(db, 'default_categories');
    const snapshot = await getDocs(defaultCategoriesRef);

    if (snapshot.empty) {
      console.log('Creating default categories...');
      const createdCategories = await Promise.all(
        initialDefaultCategories.map(async (category: Omit<Category, 'id'>) => {
          const docRef = await addDoc(defaultCategoriesRef, category);
          return { ...category, id: docRef.id };
        })
      );
      return createdCategories;
    } else {
      console.log('Loading existing default categories...');
      return snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      } as Category));
    }
  } catch (error) {
    console.error('Error initializing default categories:', error);
    throw error;
  }
};

export const initializeUserCategories = async (userId: string) => {
  try {
    const categoriesRef = collection(db, 'categories');
    const userCategoriesQuery = query(categoriesRef, where('userId', '==', userId));
    const userCategoriesSnapshot = await getDocs(userCategoriesQuery);

    // If user has no categories, create from default categories
    if (userCategoriesSnapshot.empty) {
      const defaultCategories = await getDefaultCategories();
      const batch = writeBatch(db);
      const newCategories: Category[] = [];

      // Create new categories in a batch
      for (const category of defaultCategories) {
        const docRef = doc(collection(db, 'categories'));
        const newCategory: Category = {
          name: category.name,
          type: category.type,
          icon: category.icon,
          color: category.color,
          id: docRef.id,
          userId,
          transactionCount: 0,
          wallets: [],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };
        batch.set(docRef, newCategory);
        newCategories.push(newCategory);
      }

      // Commit the batch
      await batch.commit();
      return newCategories;
    }

    // If user already has categories, return existing categories
    return userCategoriesSnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    } as Category));
  } catch (error) {
    console.error('Error initializing user categories:', error);
    throw error;
  }
};

export const resetAllUsersCategories = async () => {
  try {
    // Get default categories first
    const defaultCategories = await getDefaultCategories();
    if (!defaultCategories.length) {
      throw new Error('No default categories found');
    }

    // Get all users
    const usersRef = collection(db, 'users');
    const usersSnapshot = await getDocs(usersRef);

    // Process users in chunks to avoid batch size limits
    const chunkSize = 100;
    for (let i = 0; i < usersSnapshot.docs.length; i += chunkSize) {
      const userChunk = usersSnapshot.docs.slice(i, i + chunkSize);
      const batch = writeBatch(db);

      for (const userDoc of userChunk) {
        const userId = userDoc.id;

        // Get user's categories
        const categoriesRef = collection(db, 'categories');
        const userCategoriesQuery = query(categoriesRef, where('userId', '==', userId));
        const userCategoriesSnapshot = await getDocs(userCategoriesQuery);

        // Delete categories with no transactions
        userCategoriesSnapshot.docs.forEach(doc => {
          const category = doc.data() as Category;
          if (category.transactionCount === 0) {
            batch.delete(doc.ref);
          }
        });

        // Create new categories
        defaultCategories.forEach(defaultCategory => {
          const docRef = doc(collection(db, 'categories'));
          const newCategory: Category = {
            name: defaultCategory.name,
            type: defaultCategory.type,
            icon: defaultCategory.icon,
            color: defaultCategory.color,
            id: docRef.id,
            userId,
            transactionCount: 0,
            wallets: [],
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          };
          batch.set(docRef, newCategory);
        });
      }

      // Commit the batch
      await batch.commit();
      console.log(`Processed ${userChunk.length} users`);
    }

    return true;
  } catch (error) {
    console.error('Error resetting categories for all users:', error);
    throw error;
  }
};

export const addCategory = async (category: Omit<Category, 'id'>) => {
  const docRef = doc(collection(db, 'categories'));
  const newCategory: Category = {
    ...category,
    id: docRef.id,
    transactionCount: 0,
    wallets: [],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };
  await setDoc(docRef, newCategory);
  return newCategory;
};

export const updateCategory = async (id: string, updates: Partial<Category>) => {
  const categoriesRef = collection(db, 'categories');
  const updatedData = {
    ...updates,
    updatedAt: new Date().toISOString()
  };
  await updateDoc(doc(categoriesRef, id), updatedData);
  return updatedData;
};

export const deleteCategories = async (ids: string[]) => {
  const categoriesRef = collection(db, 'categories');
  const batch = writeBatch(db);

  // Check if any categories have transactions
  const categoriesSnapshot = await Promise.all(
    ids.map(id => getDocs(query(categoriesRef, where('id', '==', id))))
  );

  const hasTransactions = categoriesSnapshot.some(snapshot => 
    snapshot.docs.some(doc => doc.data()?.transactionCount > 0)
  );

  if (hasTransactions) {
    throw new Error('Cannot delete categories that have transactions');
  }

  // Delete categories in batch
  ids.forEach(id => {
    batch.delete(doc(categoriesRef, id));
  });

  await batch.commit();
};

export const addDefaultCategory = async (category: Omit<Category, 'id'>) => {
  const docRef = doc(collection(db, 'default_categories'));
  const newCategory = {
    ...category,
    id: docRef.id,
    userId: SYSTEM_USER_ID,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };
  await setDoc(docRef, newCategory);
  return newCategory;
};

export const updateDefaultCategory = async (id: string, updates: Partial<Category>) => {
  const updatedData = {
    ...updates,
    updatedAt: new Date().toISOString()
  };
  await updateDoc(doc(db, 'default_categories', id), updatedData);
  return updatedData;
};

export const deleteDefaultCategory = async (id: string) => {
  await deleteDoc(doc(db, 'default_categories', id));
};

export const getUserCategories = async (userId: string): Promise<Category[]> => {
  try {
    const categoriesRef = collection(db, 'categories');
    const q = query(categoriesRef, where('userId', '==', userId));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    } as Category));
  } catch (error) {
    console.error('Error getting user categories:', error);
    throw error;
  }
};
