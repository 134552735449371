const translations = {
  title: 'Kategoriler',
  subtitle: 'İşlem kategorilerinizi yönetin',
  addCategoryButton: 'Kategori Ekle',
  categoryTypes: {
    income: 'Gelir Kategorileri',
    expense: 'Gider Kategorileri'
  },
  details: {
    transactions: 'işlem',
    transactionsIn: 'içinde'
  },
  actions: {
    deleteSelected: 'Seçilenleri Sil',
    edit: 'Kategoriyi Düzenle',
    delete: 'Kategoriyi Sil'
  },
  deleteModal: {
    title: 'Kategoriyi Sil',
    titleMultiple: 'Kategorileri Sil',
    message: 'Bu kategoriyi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
    messageMultiple: 'Seçili kategorileri silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
    confirmButton: 'Evet, kategoriyi sil',
    confirmButtonMultiple: 'Evet, kategorileri sil',
    cancelButton: 'Hayır, vazgeç',
    cancelButtonMultiple: 'Hayır, vazgeç'
  }
};

export default translations;
