const translations = {
  welcome: 'Welcome',
  overview: {
    title: 'Your financial overview',
    newTransaction: 'New Transaction',
    wallets: {
      title: 'My Wallets',
      new: 'Add New Wallet',
      empty: {
        title: 'Add New Wallet',
        description: 'Create a new wallet to get started'
      }
    }
  },
  stats: {
    income: 'Income',
    expenses: 'Expenses',
    balance: 'Balance',
    transactions: 'Transactions',
    savingsRate: 'Savings Rate',
    totalBalance: 'Total Balance',
    totalIncome: 'Total Income',
    totalExpenses: 'Total Expenses',
    changes: {
      positive: '+{value}%',
      negative: '-{value}%'
    }
  },
  upcomingPayments: {
    title: 'Upcoming Payments & Income',
    noPayments: 'No upcoming payments in the next 30 days',
    creditCard: 'Credit Card Payment',
    installment: 'Installment {{current}}/{{total}}',
    debt: 'Debt Payment'
  }
};

export default translations;
