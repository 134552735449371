const translations = {
  title: 'Transactions',
  subtitle: 'Manage your transactions',
  new: 'New Transaction',
  noTransactions: 'No transactions found',
  deleteModal: {
    title: 'Delete Transactions',
    message: 'Are you sure you want to delete the selected transactions? This action cannot be undone.',
    confirmButton: 'Yes, delete transactions',
    cancelButton: 'No, keep transactions'
  }
};

export default translations;
