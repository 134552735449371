import { TranslationModule } from '../../types';
import { BudgetModalTranslations } from './types';
import { translations as enTranslations } from './en';
import { translations as trTranslations } from './tr';
import { getTranslations as getCommonTranslations } from '../../common';

export const translations: TranslationModule<BudgetModalTranslations> = {
  en: {
    ...enTranslations,
    buttons: {
      cancel: getCommonTranslations('en').cancel,
      save: getCommonTranslations('en').save,
      create: getCommonTranslations('en').create
    }
  },
  tr: {
    ...trTranslations,
    buttons: {
      cancel: getCommonTranslations('tr').cancel,
      save: getCommonTranslations('tr').save,
      create: getCommonTranslations('tr').create
    }
  }
};

// Re-export the types
export type { BudgetModalTranslations };

// Helper to get translations for a specific language
export const getTranslations = (lang: 'en' | 'tr'): BudgetModalTranslations => {
  return translations[lang];
};
