const translations = {
  login: {
    title: 'Hesabınıza giriş yapın',
    noAccount: 'Hesabınız yok mu?',
    createAccount: 'Ücretsiz hesap oluşturun',
    email: 'E-posta adresi',
    password: 'Şifre',
    rememberMe: 'Beni hatırla',
    forgotPassword: 'Şifrenizi mi unuttunuz?',
    submit: 'Giriş yap',
    errors: {
      invalidEmail: 'Geçersiz e-posta adresi',
      invalidPassword: 'Geçersiz şifre',
      userNotFound: 'Bu e-posta ile kayıtlı hesap bulunamadı',
      tooManyAttempts: 'Çok fazla deneme. Lütfen daha sonra tekrar deneyin.'
    }
  },
  register: {
    title: 'Hesap oluşturun',
    haveAccount: 'Zaten hesabınız var mı?',
    signIn: 'Giriş yapın',
    name: 'Ad soyad',
    email: 'E-posta adresi',
    password: 'Şifre',
    confirmPassword: 'Şifreyi onaylayın',
    submit: 'Hesap oluştur',
    errors: {
      emailInUse: 'Bu e-posta adresi zaten kullanımda',
      passwordTooWeak: 'Şifre çok zayıf',
      passwordsDontMatch: 'Şifreler eşleşmiyor'
    }
  },
  socialSignIn: {
    continueWith: 'Veya şununla devam edin',
    google: 'Google',
    facebook: 'Facebook',
    apple: 'Apple',
    microsoft: 'Microsoft'
  },
  forgotPassword: {
    title: 'Şifrenizi sıfırlayın',
    description: 'E-posta adresinizi girin, size şifre sıfırlama bağlantısı göndereceğiz.',
    email: 'E-posta adresi',
    submit: 'Sıfırlama bağlantısı gönder',
    success: 'Bu e-posta ile bir hesap varsa, şifre sıfırlama bağlantısı alacaksınız.',
    errors: {
      userNotFound: 'Bu e-posta ile kayıtlı hesap bulunamadı',
      tooManyAttempts: 'Çok fazla deneme. Lütfen daha sonra tekrar deneyin.'
    }
  },
  resetPassword: {
    title: 'Yeni şifre belirleyin',
    password: 'Yeni şifre',
    confirmPassword: 'Yeni şifreyi onaylayın',
    submit: 'Şifreyi sıfırla',
    success: 'Şifreniz başarıyla sıfırlandı.',
    errors: {
      invalidToken: 'Geçersiz veya süresi dolmuş sıfırlama bağlantısı',
      passwordTooWeak: 'Şifre çok zayıf',
      passwordsDontMatch: 'Şifreler eşleşmiyor'
    }
  },
  verifyEmail: {
    title: 'E-postanızı doğrulayın',
    description: 'Lütfen e-postanızdaki doğrulama bağlantısını kontrol edin.',
    resendButton: 'Doğrulama e-postasını yeniden gönder',
    sending: 'Gönderiliyor...',
    success: 'E-posta başarıyla doğrulandı.',
    errors: {
      invalidToken: 'Geçersiz veya süresi dolmuş doğrulama bağlantısı',
      alreadyVerified: 'E-posta zaten doğrulanmış',
      tooManyAttempts: 'Çok fazla deneme. Lütfen daha sonra tekrar deneyin.'
    }
  }
};

export default translations;
