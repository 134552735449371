export const sidebar = {
  appName: 'CostCaddy',
  tagline: 'Giderlerinizi kolayca takip edin',
  menu: {
    overview: 'Genel Bakış',
    dashboard: 'Panel',
    transactions: 'İşlemler',
    wallets: 'Cüzdanlar',
    budgets: 'Bütçeler',
    reports: 'Raporlar',
    creditCards: 'Kredi Kartları',
    subscriptions: 'Abonelikler',
    settings: 'Ayarlar'
  },
  settings: {
    categories: 'Kategoriler',
    labels: 'Etiketler',
    notifications: 'Bildirimler',
    inviteMembers: 'Üye Davet Et',
    email: 'E-posta Ayarları',
    account: 'Hesap'
  },
  admin: {
    title: 'Yönetici',
    users: 'Kullanıcılar',
    settings: 'Ayarlar',
    subscriptions: 'Abonelikler',
    platforms: 'Platformlar',
    categories: 'Kategoriler',
    labels: 'Etiketler',
    emails: 'E-postalar',
    languages: 'Diller',
    dashboard: 'Panel'
  }
};
