import { initializeApp } from 'firebase/app';
import { getAuth, browserLocalPersistence, setPersistence } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAGGYbZEAH5K76DN817V9pHLWYeL-FR7gI",
  authDomain: "codecaddy-c83c5.firebaseapp.com",
  projectId: "codecaddy-c83c5",
  storageBucket: "codecaddy-c83c5.firebasestorage.app",
  messagingSenderId: "417292102286",
  appId: "1:417292102286:web:67b55551e4949889b677df"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, 'europe-west1');

// Determine if we should use emulators
const useEmulators = false; // Set this to false to use production

if (useEmulators) {
  console.log('🔧 Using Firebase Emulators');
  try {
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectStorageEmulator(storage, 'localhost', 9199);
    console.log('✅ Connected to Firebase emulators successfully');
  } catch (error) {
    console.error('❌ Failed to connect to emulators:', error);
  }
} else {
  console.log('🚀 Using Firebase Production Environment');
}

// Set default persistence to local
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('✅ Firebase: Local persistence set successfully');
    localStorage.setItem('authPersistence', 'local');
  })
  .catch((error) => {
    console.error('❌ Firebase: Persistence error:', error);
  });

// Log initial auth state
auth.onAuthStateChanged((user) => {
  console.log('👤 Firebase: Auth state changed:', {
    isSignedIn: !!user,
    email: user?.email,
    persistence: localStorage.getItem('authPersistence')
  });
});

export { app, auth, db, storage, functions };
