import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useWallets } from './WalletContext';
import { useCreditCards } from './CreditCardContext';
import { useBudgets } from './BudgetContext';
import { Transaction } from '../types';
import * as transactionService from '../services/transactions';
import * as categoryService from '../services/categories';
import * as labelService from '../services/labels';
import * as walletService from '../services/wallets';
import * as creditCardService from '../services/creditCards';
import * as budgetService from '../services/budgets';
import { doc, getDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface TransactionContextType {
  transactions: Transaction[];
  isLoading: boolean;
  error: string | null;
  addTransaction: (transaction: Omit<Transaction, 'id'>) => Promise<void>;
  deleteTransaction: (id: string) => Promise<void>;
  updateTransaction: (id: string, transaction: Partial<Transaction>) => Promise<void>;
  filterTransactions: (filters: Partial<Transaction>) => Transaction[];
}

const TransactionContext = createContext<TransactionContextType | undefined>(undefined);

export const useTransactions = () => {
  const context = useContext(TransactionContext);
  if (!context) throw new Error('useTransactions must be used within TransactionProvider');
  return context;
};
export const TransactionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const { refreshWallets } = useWallets();
  const { creditCards } = useCreditCards();
  const { budgets } = useBudgets();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadTransactions = async () => {
      if (!user) {
        setTransactions([]);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        const userTransactions = await transactionService.getUserTransactions(user.id);
        setTransactions(userTransactions);
      } catch (err) {
        console.error('Error loading transactions:', err);
        setError(err instanceof Error ? err.message : 'Failed to load transactions');
      } finally {
        setIsLoading(false);
      }
    };

    loadTransactions();
  }, [user]);

  // Helper function to create system notification
  const createSystemNotification = async (
    userId: string,
    transactionData: any,
    action: 'created' | 'updated' | 'deleted'
  ) => {
    try {
      // Get user's notification preferences
      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();
      if (!userData?.notificationPreferences?.systemNotifications || !userData?.notificationPreferences?.transactions) return;

      // Get wallet name
      const walletDoc = await getDoc(doc(db, 'wallets', transactionData.walletId));
      const walletData = walletDoc.data();
      const walletName = walletData?.name || 'Unknown Wallet';

      // Create notification
      await addDoc(collection(db, 'notifications'), {
        userId,
        type: 'transaction',
        data: {
          action,
          amount: transactionData.amount,
          currency: transactionData.currency,
          category: transactionData.category,
          walletName,
          date: transactionData.date,
          description: transactionData.description,
          transactionUrl: `/transactions/${transactionData.id}`
        },
        read: false,
        createdAt: serverTimestamp()
      });
    } catch (error) {
      console.error('Error creating system notification:', error);
    }
  };

  // Helper function to update budgets based on transaction
  const updateBudgets = async (
    transaction: Transaction,
    oldTransaction?: Transaction,
    isDelete: boolean = false
  ) => {
    if (!user || transaction.type !== 'expense') return;

    try {
      // Find budgets that match this transaction's category or wallet
      const affectedBudgets = budgets.filter(budget => {
        const matchesCategory = budget.categoryIds.includes(transaction.category);
        const matchesWallet = budget.wallets.includes(transaction.walletId);
        return matchesCategory || matchesWallet;
      });

      // Get complete user data with notification preferences
      const userDoc = await getDoc(doc(db, 'users', user.id));
      const userData = userDoc.data();
      if (!userData) return;

      // Create user object with full notification preferences
      const adaptedUser = {
        ...user,
        notificationPreferences: userData.notificationPreferences || {
          transactions: true,
          upcomingPayments: true,
          invitedMemberActivities: true,
          weeklyReports: true,
          monthlyReports: true,
          emailNotifications: true,
          systemNotifications: true,
          notifyInvitedMembers: false,
        }
      };

      for (const budget of affectedBudgets) {
        let amountChange = 0;

        if (isDelete) {
          // If deleting an expense transaction, reduce the spent amount
          amountChange = -transaction.amount;
        } else if (oldTransaction) {
          // Handle type changes and amount changes
          if (oldTransaction.type === 'expense' && transaction.type !== 'expense') {
            // Changed from expense to non-expense: remove old amount
            amountChange = -oldTransaction.amount;
          } else if (oldTransaction.type !== 'expense' && transaction.type === 'expense') {
            // Changed from non-expense to expense: add new amount
            amountChange = transaction.amount;
          } else if (transaction.type === 'expense') {
            // Both are expenses: calculate the difference
            amountChange = transaction.amount - oldTransaction.amount;
          }
        } else {
          // New transaction
          amountChange = transaction.amount;
        }

        if (amountChange !== 0) {
          await budgetService.updateBudgetSpending(budget.id, amountChange, adaptedUser);
        }
      }
    } catch (error) {
      console.error('Error updating budgets:', error);
    }
  };

  const addTransaction = async (data: Omit<Transaction, 'id'>) => {
    if (!user) return;
    setIsLoading(true);
    setError(null);

    try {
      // Create transaction
      const transactionId = await transactionService.createTransaction(user.id, data);

      // Update category usage
      if (data.category) {
        const categories = await categoryService.getUserCategories(user.id);
        const category = categories.find(c => c.id === data.category);
        if (category) {
          await categoryService.updateCategory(category.id, {
            transactionCount: category.transactionCount + 1
          });
        }
      }

      // Update label usage
      if (data.labels?.length) {
        await Promise.all(
          data.labels.map(labelId => labelService.incrementLabelUsage(labelId))
        );
      }

      // Update wallet balance
      const amount = data.type === 'expense' ? -data.amount : data.amount;
      await walletService.updateWalletBalance(data.walletId, amount);

      // Update credit card balance if payment method is credit
      if (data.paymentMethod === 'credit' && data.creditCardId) {
        const creditCard = creditCards.find(card => card.id === data.creditCardId);
        if (creditCard) {
          await creditCardService.updateCreditCard(data.creditCardId, {
            balance: creditCard.balance + Math.abs(data.amount)
          });
        }
      }

      const newTransaction = { id: transactionId, ...data };
      setTransactions(current => [newTransaction, ...current]);

      // Create system notification
      await createSystemNotification(user.id, { ...newTransaction, id: transactionId }, 'created');

      // Update budgets
      await updateBudgets(newTransaction);

      // Refresh wallet balances
      await refreshWallets();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create transaction');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateTransaction = async (id: string, updates: Partial<Transaction>) => {
    if (!user) return;
    setIsLoading(true);
    setError(null);

    try {
      const oldTransaction = transactions.find(t => t.id === id);
      if (!oldTransaction) throw new Error('Transaction not found');

      await transactionService.updateTransaction(id, updates);

      // Update category usage if changed
      if (updates.category && updates.category !== oldTransaction.category) {
        const categories = await categoryService.getUserCategories(user.id);
        
        // Decrement old category count
        if (oldTransaction.category) {
          const oldCategory = categories.find(c => c.id === oldTransaction.category);
          if (oldCategory) {
            await categoryService.updateCategory(oldCategory.id, {
              transactionCount: Math.max(0, oldCategory.transactionCount - 1)
            });
          }
        }

        // Increment new category count
        const newCategory = categories.find(c => c.id === updates.category);
        if (newCategory) {
          await categoryService.updateCategory(newCategory.id, {
            transactionCount: newCategory.transactionCount + 1
          });
        }
      }

      // Update label usage if changed
      if (updates.labels) {
        const oldLabels = oldTransaction.labels || [];
        const newLabels = updates.labels;

        // Decrement removed labels
        const removedLabels = oldLabels.filter(l => !newLabels.includes(l));
        await Promise.all(
          removedLabels.map(labelId => labelService.decrementLabelUsage(labelId))
        );

        // Increment added labels
        const addedLabels = newLabels.filter(l => !oldLabels.includes(l));
        await Promise.all(
          addedLabels.map(labelId => labelService.incrementLabelUsage(labelId))
        );
      }

      // Update wallet balance if amount or type changed
      if (updates.amount !== undefined || updates.type !== undefined || updates.walletId !== undefined) {
        const oldAmount = oldTransaction.type === 'expense' ? -oldTransaction.amount : oldTransaction.amount;
        const newAmount = (updates.type || oldTransaction.type) === 'expense' 
          ? -(updates.amount || oldTransaction.amount) 
          : (updates.amount || oldTransaction.amount);

        // If wallet changed, update both old and new wallet balances
        if (updates.walletId && updates.walletId !== oldTransaction.walletId) {
          await Promise.all([
            walletService.updateWalletBalance(oldTransaction.walletId, -oldAmount),
            walletService.updateWalletBalance(updates.walletId, newAmount)
          ]);
        } else {
          // Otherwise, just update the current wallet's balance
          await walletService.updateWalletBalanceForTransaction(
            oldTransaction.walletId,
            oldAmount,
            newAmount
          );
        }
      }

      // Update credit card balance if payment method or amount changed
      if (oldTransaction.paymentMethod === 'credit' && oldTransaction.creditCardId) {
        const creditCard = creditCards.find(card => card.id === oldTransaction.creditCardId);
        if (creditCard) {
          const oldBalance = creditCard.balance - Math.abs(oldTransaction.amount);
          await creditCardService.updateCreditCard(oldTransaction.creditCardId, {
            balance: oldBalance + (updates.amount ? Math.abs(updates.amount) : Math.abs(oldTransaction.amount))
          });
        }
      }

      const updatedTransaction = { ...oldTransaction, ...updates };
      setTransactions(current =>
        current.map(transaction =>
          transaction.id === id ? updatedTransaction : transaction
        )
      );

      // Create system notification
      await createSystemNotification(user.id, updatedTransaction, 'updated');

      // Update budgets
      await updateBudgets(updatedTransaction, oldTransaction);

      // Refresh wallet balances
      await refreshWallets();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update transaction');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTransaction = async (id: string) => {
    if (!user) return;
    setIsLoading(true);
    setError(null);

    try {
      const transaction = transactions.find(t => t.id === id);
      if (!transaction) throw new Error('Transaction not found');

      await transactionService.deleteTransaction(id);

      // Update category usage
      if (transaction.category) {
        const categories = await categoryService.getUserCategories(user.id);
        const category = categories.find(c => c.id === transaction.category);
        if (category) {
          await categoryService.updateCategory(category.id, {
            transactionCount: Math.max(0, category.transactionCount - 1)
          });
        }
      }

      // Update label usage
      if (transaction.labels?.length) {
        await Promise.all(
          transaction.labels.map(labelId => labelService.decrementLabelUsage(labelId))
        );
      }

      // Update wallet balance
      const amount = transaction.type === 'expense' ? transaction.amount : -transaction.amount;
      await walletService.updateWalletBalance(transaction.walletId, amount);

      // Update credit card balance if payment method is credit
      if (transaction.paymentMethod === 'credit' && transaction.creditCardId) {
        const creditCard = creditCards.find(card => card.id === transaction.creditCardId);
        if (creditCard) {
          await creditCardService.updateCreditCard(transaction.creditCardId, {
            balance: creditCard.balance - Math.abs(transaction.amount)
          });
        }
      }

      // Create system notification
      await createSystemNotification(user.id, transaction, 'deleted');

      // Update budgets
      await updateBudgets(transaction, undefined, true);

      setTransactions(current => current.filter(t => t.id !== id));

      // Refresh wallet balances
      await refreshWallets();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete transaction');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const filterTransactions = (filters: Partial<Transaction>) => {
    return transactions.filter(transaction => {
      return Object.entries(filters).every(([key, value]) => {
        if (value === undefined) return true;
        return transaction[key as keyof Transaction] === value;
      });
    });
  };

  return (
    <TransactionContext.Provider value={{
      transactions,
      isLoading,
      error,
      addTransaction,
      deleteTransaction,
      updateTransaction,
      filterTransactions
    }}>
      {children}
    </TransactionContext.Provider>
  );
};
