const translations = {
  title: 'İşlemler',
  subtitle: 'İşlemlerinizi yönetin',
  new: 'Yeni İşlem',
  noTransactions: 'İşlem bulunamadı',
  deleteModal: {
    title: 'İşlemleri Sil',
    message: 'Seçili işlemleri silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
    confirmButton: 'Evet, işlemleri sil',
    cancelButton: 'Hayır, vazgeç'
  }
};

export default translations;
