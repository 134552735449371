import { TranslationsContainer } from './types';
import { translations as commonTranslations } from './common';
import { translations as navigationTranslations } from './navigation';
import { translations as dashboardTranslations, getTranslations as getDashboardTranslations } from './dashboard';
import { translations as reportsTranslations } from './reports';
import { translations as transactionsTranslations } from './transactions';
import { translations as walletsTranslations } from './wallets';
import { translations as authTranslations, getTranslations as getAuthTranslations } from './auth';

// Component translations
import { translations as sidebarTranslations } from './components/sidebar';
import { translations as headerTranslations } from './components/header';
import { translations as emailVerificationBannerTranslations } from './components/emailVerificationBanner';
import { translations as exportButtonTranslations } from './components/exportButton';
import { translations as planLimitModalTranslations } from './components/planLimitModal';
import { translations as budgetModalTranslations } from './components/budgetModal';
import { translations as creditCardModalTranslations } from './components/creditCardModal';
import { translations as subscriptionModalTranslations } from './components/subscriptionModal';
import { translations as subscriptionCardTranslations } from './components/subscriptionCard';

// Page translations
import { translations as creditCardsPageTranslations } from './pages/creditCardsPage';
import { translations as accountPageTranslations } from './pages/accountPage';
import { translations as budgetDetailsPageTranslations } from './pages/budgetDetailsPage';
import { translations as budgetsPageTranslations } from './pages/budgetsPage';
import { translations as walletsPageTranslations } from './pages/walletsPage';
import { translations as categoriesPageTranslations } from './pages/categoriesPage';
import { translations as labelsPageTranslations } from './pages/labelsPage';
import { translations as inviteMembersPageTranslations } from './pages/inviteMembersPage';
import { translations as acceptInvitationPageTranslations } from './pages/acceptInvitationPage';
import { translations as transactionsPageTranslations } from './pages/transactionsPage';
import { translations as reportsPageTranslations } from './pages/reportsPage';
import { translations as subscriptionsPageTranslations } from './pages/subscriptionsPage';

export type Language = 'en' | 'tr';

export const languages = {
  en: { name: 'English', flag: 'GB' }, // 🇬🇧
  tr: { name: 'Türkçe', flag: 'TR' }  // 🇹🇷
} as const;

// Helper function to get translations from a module that might have them nested
const getModuleTranslations = (module: any) => {
  return module.translations || module;
};

// Export translations
export const translations: TranslationsContainer = {
  translations: {
    en: {
      common: getModuleTranslations(commonTranslations).en,
      navigation: getModuleTranslations(navigationTranslations).en,
      dashboard: getModuleTranslations(dashboardTranslations).en,
      reports: getModuleTranslations(reportsTranslations).en,
      transactions: getModuleTranslations(transactionsTranslations).en,
      wallets: getModuleTranslations(walletsTranslations).en,
      auth: getModuleTranslations(authTranslations).en,
      components: {
        sidebar: getModuleTranslations(sidebarTranslations).en,
        header: getModuleTranslations(headerTranslations).en,
        emailVerificationBanner: getModuleTranslations(emailVerificationBannerTranslations).en,
        exportButton: getModuleTranslations(exportButtonTranslations).en,
        planLimitModal: getModuleTranslations(planLimitModalTranslations).en,
        budgetModal: getModuleTranslations(budgetModalTranslations).en,
        creditCardModal: getModuleTranslations(creditCardModalTranslations).en,
        subscriptionModal: getModuleTranslations(subscriptionModalTranslations).en,
        subscriptionCard: getModuleTranslations(subscriptionCardTranslations).en
      },
      pages: {
        creditCardsPage: getModuleTranslations(creditCardsPageTranslations).en,
        accountPage: getModuleTranslations(accountPageTranslations).en,
        budgetDetailsPage: getModuleTranslations(budgetDetailsPageTranslations).en,
        budgetsPage: getModuleTranslations(budgetsPageTranslations).en,
        walletsPage: getModuleTranslations(walletsPageTranslations).en,
        categoriesPage: getModuleTranslations(categoriesPageTranslations).en,
        labelsPage: getModuleTranslations(labelsPageTranslations).en,
        inviteMembersPage: getModuleTranslations(inviteMembersPageTranslations).en,
        acceptInvitationPage: getModuleTranslations(acceptInvitationPageTranslations).en,
        transactionsPage: getModuleTranslations(transactionsPageTranslations).en,
        reportsPage: getModuleTranslations(reportsPageTranslations).en,
        subscriptionsPage: getModuleTranslations(subscriptionsPageTranslations).en
      }
    },
    tr: {
      common: getModuleTranslations(commonTranslations).tr,
      navigation: getModuleTranslations(navigationTranslations).tr,
      dashboard: getModuleTranslations(dashboardTranslations).tr,
      reports: getModuleTranslations(reportsTranslations).tr,
      transactions: getModuleTranslations(transactionsTranslations).tr,
      wallets: getModuleTranslations(walletsTranslations).tr,
      auth: getModuleTranslations(authTranslations).tr,
      components: {
        sidebar: getModuleTranslations(sidebarTranslations).tr,
        header: getModuleTranslations(headerTranslations).tr,
        emailVerificationBanner: getModuleTranslations(emailVerificationBannerTranslations).tr,
        exportButton: getModuleTranslations(exportButtonTranslations).tr,
        planLimitModal: getModuleTranslations(planLimitModalTranslations).tr,
        budgetModal: getModuleTranslations(budgetModalTranslations).tr,
        creditCardModal: getModuleTranslations(creditCardModalTranslations).tr,
        subscriptionModal: getModuleTranslations(subscriptionModalTranslations).tr,
        subscriptionCard: getModuleTranslations(subscriptionCardTranslations).tr
      },
      pages: {
        creditCardsPage: getModuleTranslations(creditCardsPageTranslations).tr,
        accountPage: getModuleTranslations(accountPageTranslations).tr,
        budgetDetailsPage: getModuleTranslations(budgetDetailsPageTranslations).tr,
        budgetsPage: getModuleTranslations(budgetsPageTranslations).tr,
        walletsPage: getModuleTranslations(walletsPageTranslations).tr,
        categoriesPage: getModuleTranslations(categoriesPageTranslations).tr,
        labelsPage: getModuleTranslations(labelsPageTranslations).tr,
        inviteMembersPage: getModuleTranslations(inviteMembersPageTranslations).tr,
        acceptInvitationPage: getModuleTranslations(acceptInvitationPageTranslations).tr,
        transactionsPage: getModuleTranslations(transactionsPageTranslations).tr,
        reportsPage: getModuleTranslations(reportsPageTranslations).tr,
        subscriptionsPage: getModuleTranslations(subscriptionsPageTranslations).tr
      }
    }
  }
};

// Type guard to check if a language is supported
export const isSupportedLanguage = (lang: string): lang is Language => {
  return lang === 'en' || lang === 'tr';
};

// Helper to get translations for a specific language
export const getTranslations = (lang: string) => {
  if (isSupportedLanguage(lang)) {
    return translations.translations[lang];
  }
  return translations.translations.en; // Fallback to English
};

// Helper to get component translations
export const getComponentTranslations = (lang: Language, componentName: string) => {
  const allTranslations = getTranslations(lang);
  const components = allTranslations?.components as Record<string, Record<string, any>>;
  if (components && componentName in components) {
    return components[componentName];
  }
  return {};
};

// Re-export the translations helpers
export { getDashboardTranslations, getAuthTranslations };
