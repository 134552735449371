import { WalletLanguage, WalletTranslations } from './types';
import en from './en';
import tr from './tr';

export const translations: Record<WalletLanguage, WalletTranslations> = {
  en,
  tr
};

export const getTranslations = (language: WalletLanguage): WalletTranslations => {
  return translations[language];
};

export type { WalletTranslations, WalletLanguage };
