export const acceptInvitationPage = {
  title: 'Accept Invitation',
  description: 'You have been invited to join a financial tracking workspace',
  invitedBy: 'Invited by',
  permissions: {
    title: 'Permissions',
    viewer: 'Viewer - Can view financial data',
    editor: 'Editor - Can view and edit financial data',
    admin: 'Admin - Full access to workspace settings'
  },
  actions: {
    accept: 'Accept Invitation',
    decline: 'Decline Invitation'
  },
  status: {
    pending: 'Invitation Pending',
    accepted: 'Invitation Accepted',
    declined: 'Invitation Declined'
  },
  errors: {
    invitationExpired: 'Invitation has expired',
    alreadyMember: 'You are already a member of this workspace',
    invitationNotFound: 'Invitation not found'
  },
  workspace: {
    name: 'Workspace Name',
    members: 'Current Members'
  }
};
