import React, { createContext, useContext, useState, useEffect } from 'react';
import { Budget } from '../types/budget';
import { createBudget, getUserBudgets, updateBudget as updateBudgetInDb, deleteBudget as deleteBudgetInDb } from '../services/budgets';
import { useAuth } from './AuthContext';
import { recalculateBudgetSpending, resetAllBudgetSpendings } from '../utils/budgetUtils';

interface BudgetContextType {
  budgets: Budget[];
  addBudget: (budget: Omit<Budget, 'id' | 'spent' | 'remaining'>) => Promise<void>;
  updateBudget: (id: string, budget: Partial<Budget>) => Promise<void>;
  deleteBudget: (id: string) => Promise<void>;
  getBudgetProgress: (id: string) => { spent: number; remaining: number; percentage: number };
  isLoading: boolean;
  error: string | null;
  refreshBudgets: () => void;
  recalculateBudgetSpending: (budgetId: string) => Promise<void>;
  resetAllBudgetSpendings: () => Promise<void>;
}

const BudgetContext = createContext<BudgetContextType | undefined>(undefined);

export const useBudgets = () => {
  const context = useContext(BudgetContext);
  if (!context) throw new Error('useBudgets must be used within BudgetProvider');
  return context;
};

export const BudgetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [budgets, setBudgets] = useState<Budget[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const loadBudgets = async () => {
      if (!user) {
        setBudgets([]);
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const userBudgets = await getUserBudgets(user.id);
        setBudgets(userBudgets);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load budgets');
      } finally {
        setIsLoading(false);
      }
    };

    loadBudgets();
  }, [user, refresh]);

  const addBudget = async (newBudget: Omit<Budget, 'id' | 'spent' | 'remaining'>) => {
    if (!user) throw new Error('User must be logged in to create a budget');

    try {
      const budgetId = await createBudget(user.id, {
        ...newBudget,
        spent: 0,
        remaining: newBudget.amount
      });

      const budget: Budget = {
        ...newBudget,
        id: budgetId,
        spent: 0,
        remaining: newBudget.amount
      };

      setBudgets(current => [...current, budget]);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create budget');
      throw err;
    }
  };

  const updateBudget = async (id: string, updates: Partial<Budget>) => {
    try {
      await updateBudgetInDb(id, updates);
      setBudgets(current =>
        current.map(budget =>
          budget.id === id
            ? {
                ...budget,
                ...updates,
                remaining: updates.amount !== undefined 
                  ? updates.amount - (updates.spent ?? budget.spent)
                  : budget.amount - (updates.spent ?? budget.spent)
              }
            : budget
        )
      );
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update budget');
      throw err;
    }
  };

  const deleteBudget = async (id: string) => {
    try {
      await deleteBudgetInDb(id);
      setBudgets(current => current.filter(budget => budget.id !== id));
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete budget');
      throw err;
    }
  };

  const getBudgetProgress = (id: string) => {
    const budget = budgets.find(b => b.id === id);
    if (!budget) return { spent: 0, remaining: 0, percentage: 0 };

    const percentage = (Math.abs(budget.spent) / budget.amount) * 100;
    return {
      spent: budget.spent,
      remaining: budget.remaining,
      percentage: Math.min(percentage, 100)
    };
  };

  const refreshBudgets = () => {
    setRefresh(!refresh);
  };

  const handleRecalculateBudgetSpending = async (budgetId: string) => {
    try {
      await recalculateBudgetSpending(budgetId);
      refreshBudgets();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to recalculate budget spending');
    }
  };

  const handleResetAllBudgetSpendings = async () => {
    try {
      await resetAllBudgetSpendings(budgets);
      refreshBudgets();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to reset all budget spendings');
    }
  };

  return (
    <BudgetContext.Provider value={{
      budgets,
      addBudget,
      updateBudget,
      deleteBudget,
      getBudgetProgress,
      isLoading,
      error,
      refreshBudgets,
      recalculateBudgetSpending: handleRecalculateBudgetSpending,
      resetAllBudgetSpendings: handleResetAllBudgetSpendings
    }}>
      {children}
    </BudgetContext.Provider>
  );
};
