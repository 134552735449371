export const reportsPage = {
  title: 'Financial Reports',
  subtitle: 'Analyze your financial activity and trends',
  noReportsAvailable: 'No reports available',
  generateReportButton: 'Generate Report',
  filters: {
    dateRange: 'Date Range',
    category: 'Category',
    wallet: 'Wallet',
    transactionType: 'Transaction Type'
  },
  reportTypes: {
    expenses: 'Expenses Report',
    income: 'Income Report',
    cashFlow: 'Cash Flow Report',
    categoryBreakdown: 'Category Breakdown Report'
  },
  summaryCards: {
    totalBalance: 'Total Balance',
    totalIncome: 'Total Income',
    totalExpenses: 'Total Expenses',
    savingsRate: 'Savings Rate',
    creditUtilization: 'Credit Utilization',
    monthlySubscriptions: 'Monthly Subscriptions',
    budgetUtilization: 'Budget Utilization',
    creditSpending: 'Credit Spending',
    activeSubscriptions: 'active subscriptions',
    categoriesOverBudget: 'categories over budget',
    thisPeriod: 'This period'
  },
  charts: {
    expensesByCategory: 'Expenses by Category',
    income: 'Income',
    expenses: 'Expenses',
    debt: 'Debt',
    incomeVsExpenses: 'Income vs Expenses Trend'
  }
};
