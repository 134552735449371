import { collection } from 'firebase/firestore';
import { db } from './firebase';

// Collection references
export const usersCollection = collection(db, 'users');
export const walletsCollection = collection(db, 'wallets');
export const transactionsCollection = collection(db, 'transactions');
export const categoriesCollection = collection(db, 'categories');
export const labelsCollection = collection(db, 'labels');
export const budgetsCollection = collection(db, 'budgets');
export const creditCardsCollection = collection(db, 'creditCards');
export const subscriptionsCollection = collection(db, 'subscriptions');
export const settingsCollection = collection(db, 'settings');