export const walletsPage = {
  title: 'Wallets',
  subtitle: 'Manage your financial accounts and track balances',
  noWallets: 'No wallets found',
  addWalletButton: 'Add Wallet',
  totalBalance: 'Total Balance',
  filters: {
    type: 'Wallet Type',
    currency: 'Currency'
  },
  walletTypes: {
    cash: 'Cash',
    bank: 'Bank Account',
    credit: 'Credit Card',
    investment: 'Investment'
  },
  actions: {
    transfer: 'Transfer',
    deposit: 'Deposit',
    withdraw: 'Withdraw'
  },
  details: {
    transactions: 'transactions',
    viewDetails: 'View Details',
    noTransactions: 'No transactions found'
  },
  balanceTypes: {
    current: 'Current Balance',
    available: 'Available Balance'
  },
  quickActions: {
    addIncome: 'Add Income',
    addExpense: 'Add Expense'
  },
  stats: {
    income: 'Income',
    expenses: 'Expenses'
  },
  addNew: {
    title: 'Add New Wallet',
    description: 'Create a new wallet to manage your finances'
  }
};
