const translations = {
  title: 'Categories',
  subtitle: 'Manage your transaction categories',
  addCategoryButton: 'Add Category',
  categoryTypes: {
    income: 'Income Categories',
    expense: 'Expense Categories'
  },
  details: {
    transactions: 'transaction',
    transactionsIn: 'in'
  },
  actions: {
    deleteSelected: 'Delete Selected',
    edit: 'Edit Category',
    delete: 'Delete Category'
  },
  deleteModal: {
    title: 'Delete Category',
    titleMultiple: 'Delete Categories',
    message: 'Are you sure you want to delete this category? This action cannot be undone.',
    messageMultiple: 'Are you sure you want to delete the selected categories? This action cannot be undone.',
    confirmButton: 'Yes, delete category',
    confirmButtonMultiple: 'Yes, delete categories',
    cancelButton: 'No, keep category',
    cancelButtonMultiple: 'No, keep categories'
  }
};

export default translations;
