import { sidebar as en } from './en';
import { sidebar as tr } from './tr';

export const translations = {
  en,
  tr
};
// Helper to get translations for a specific language
export const getTranslations = (lang: 'en' | 'tr'): SidebarTranslations => {
  return translations[lang];
};
export type SidebarTranslations = typeof en;
