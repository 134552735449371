import en from './en';
import tr from './tr';

export interface CommonTranslations {
  loading: string;
  error: string;
  success: string;
  cancel: string;
  save: string;
  delete: string;
  edit: string;
  create: string;
  update: string;
  submit: string;
  confirm: string;
  selectAll: string;
  deselectAll: string;
  search: string;
  filter: string;
  sort: string;
  actions: string;
  status: string;
  type: string;
  date: string;
  amount: string;
  description: string;
  category: string;
  categories: string;
  name: string;
  email: string;
  password: string;
  currency: string;
  balance: string;
  total: string;
  settings: string;
  profile: string;
  logout: string;
  back: string;
  close: string;
  add: string;
  remove: string;
  yes: string;
  no: string;
  or: string;
  and: string;
  all: string;
  none: string;
  other: string;
  validation: {
    required: string;
    optional: string;
  };
}

export const translations = {
  en,
  tr
} as const;

export const getTranslations = (language: 'en' | 'tr' = 'en') => {
  return translations[language];
};
