import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './AuthContext';
import { useNotification } from './NotificationContext';
import { Category } from '../types';
import { USER_ROLES } from '../constants/users';
import * as categoryService from '../services/categories';

type AdminCategoryInput = Omit<Category, 'id' | 'transactionCount' | 'wallets' | 'userId' | 'createdAt' | 'updatedAt'>;

interface CategoryContextType {
  categories: Category[];
  defaultCategories: Category[];
  isAdmin: boolean;
  isLoading: boolean;
  addCategory: (category: Omit<Category, 'id'>) => Promise<void>;
  updateCategory: (id: string, category: Partial<Category>) => Promise<void>;
  deleteCategories: (ids: string[]) => Promise<void>;
  addDefaultCategory: (category: AdminCategoryInput) => Promise<void>;
  updateDefaultCategory: (id: string, category: AdminCategoryInput) => Promise<void>;
  deleteDefaultCategory: (id: string) => Promise<void>;
}

const CategoryContext = createContext<CategoryContextType | undefined>(undefined);

export const useCategories = () => {
  const context = useContext(CategoryContext);
  if (!context) throw new Error('useCategories must be used within CategoryProvider');
  return context;
};

export const CategoryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [defaultCategories, setDefaultCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const isAdmin = user?.role === USER_ROLES.ADMIN || user?.role === USER_ROLES.SUPERADMIN;

  // Load default categories
  useEffect(() => {
    const loadDefaultCategories = async () => {
      try {
        setIsLoading(true);
        const loadedCategories = await categoryService.getDefaultCategories();
        setDefaultCategories(loadedCategories);
      } catch (error) {
        console.error('Error loading default categories:', error);
        showNotification('error', 'Failed to load default categories');
      } finally {
        setIsLoading(false);
      }
    };

    loadDefaultCategories();
  }, [showNotification]);

  // Initialize user categories
  useEffect(() => {
    const initializeUserCategories = async () => {
      if (!user) return;

      try {
        setIsLoading(true);
        const userCategories = await categoryService.initializeUserCategories(user.id);
        setCategories(userCategories);
      } catch (error) {
        console.error('Error initializing user categories:', error);
        showNotification('error', 'Failed to load categories');
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      initializeUserCategories();
    }
  }, [user, showNotification]);

  const addCategory = async (category: Omit<Category, 'id'>) => {
    if (!user) return;

    try {
      const newCategory = await categoryService.addCategory({
        ...category,
        userId: user.id
      });
      setCategories(current => [...current, newCategory]);
      showNotification('success', 'Category added successfully');
    } catch (error) {
      console.error('Error adding category:', error);
      showNotification('error', 'Failed to add category');
      throw error;
    }
  };

  const updateCategory = async (id: string, updates: Partial<Category>) => {
    try {
      const updatedData = await categoryService.updateCategory(id, updates);
      setCategories(current =>
        current.map(category =>
          category.id === id ? { ...category, ...updatedData } : category
        )
      );
      showNotification('success', 'Category updated successfully');
    } catch (error) {
      console.error('Error updating category:', error);
      showNotification('error', 'Failed to update category');
      throw error;
    }
  };

  const deleteCategories = async (ids: string[]) => {
    try {
      await categoryService.deleteCategories(ids);
      setCategories(current => current.filter(category => !ids.includes(category.id)));
      showNotification('success', 'Categories deleted successfully');
    } catch (error) {
      console.error('Error deleting categories:', error);
      showNotification('error', error instanceof Error ? error.message : 'Failed to delete categories');
      throw error;
    }
  };

  const addDefaultCategory = async (category: AdminCategoryInput) => {
    if (!isAdmin) {
      showNotification('error', 'Only admins can add default categories');
      return;
    }

    try {
      const newCategory = await categoryService.addDefaultCategory({
        ...category,
        transactionCount: 0,
        wallets: [],
        userId: 'system',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });
      setDefaultCategories(current => [...current, newCategory]);
      showNotification('success', 'Default category added successfully');
    } catch (error) {
      console.error('Error adding default category:', error);
      showNotification('error', 'Failed to add default category');
      throw error;
    }
  };

  const updateDefaultCategory = async (id: string, updates: AdminCategoryInput) => {
    if (!isAdmin) {
      showNotification('error', 'Only admins can update default categories');
      return;
    }

    try {
      const updatedData = await categoryService.updateDefaultCategory(id, updates);
      setDefaultCategories(current =>
        current.map(category =>
          category.id === id ? { ...category, ...updatedData } : category
        )
      );
      showNotification('success', 'Default category updated successfully');
    } catch (error) {
      console.error('Error updating default category:', error);
      showNotification('error', 'Failed to update default category');
      throw error;
    }
  };

  const deleteDefaultCategory = async (id: string) => {
    if (!isAdmin) {
      showNotification('error', 'Only admins can delete default categories');
      return;
    }

    try {
      await categoryService.deleteDefaultCategory(id);
      setDefaultCategories(current => current.filter(category => category.id !== id));
      showNotification('success', 'Default category deleted successfully');
    } catch (error) {
      console.error('Error deleting default category:', error);
      showNotification('error', error instanceof Error ? error.message : 'Failed to delete default category');
      throw error;
    }
  };

  return (
    <CategoryContext.Provider value={{
      categories,
      defaultCategories,
      isAdmin,
      isLoading,
      addCategory,
      updateCategory,
      deleteCategories,
      addDefaultCategory,
      updateDefaultCategory,
      deleteDefaultCategory
    }}>
      {children}
    </CategoryContext.Provider>
  );
};
