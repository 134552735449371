// Exchange rates API URL
const API_URL = 'https://v6.exchangerate-api.com/v6/fdd3b227ae11101edd567987/latest/USD';

// Update interval in milliseconds (1 hour)
const UPDATE_INTERVAL = 60 * 60 * 1000;

// LocalStorage key
const STORAGE_KEY = 'exchange_rates_cache';

// Default rates to use as fallback
const DEFAULT_RATES = {
  base: 'USD',
  rates: {
    USD: 1,
    EUR: 0.91,
    GBP: 0.79,
    TRY: 28.74,
    JPY: 147.73,
    CNY: 7.19
  },
  lastUpdated: new Date().toISOString()
};

export interface ExchangeRates {
  base: string;
  rates: { [key: string]: number };
  lastUpdated: string;
}

interface ExchangeRateApiResponse {
  result: string;
  documentation: string;
  terms_of_use: string;
  time_last_update_unix: number;
  time_last_update_utc: string;
  time_next_update_unix: number;
  time_next_update_utc: string;
  base_code: string;
  conversion_rates: {
    [key: string]: number;
  };
}

let lastFetchTime = 0;
let fetchPromise: Promise<ExchangeRates> | null = null;

// Get rates from localStorage with error handling
const getStoredRates = (): ExchangeRates | null => {
  try {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (!stored) return null;

    const rates = JSON.parse(stored) as ExchangeRates;
    
    // Validate the stored data structure
    if (!rates.base || !rates.rates || !rates.lastUpdated) {
      console.warn('Invalid stored rates format');
      return null;
    }

    return rates;
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return null;
  }
};

// Store rates in localStorage with error handling
const storeRates = (rates: ExchangeRates): void => {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(rates));
  } catch (error) {
    console.error('Error storing in localStorage:', error);
    // Clear storage if we hit quota limits
    if (error instanceof DOMException && error.name === 'QuotaExceededError') {
      try {
        localStorage.clear();
        localStorage.setItem(STORAGE_KEY, JSON.stringify(rates));
      } catch (retryError) {
        console.error('Failed to store rates even after clearing storage:', retryError);
      }
    }
  }
};

// Fetch latest exchange rates
export const fetchExchangeRates = async (): Promise<ExchangeRates> => {
  const now = Date.now();

  // If we have a pending fetch, return it
  if (fetchPromise) {
    return fetchPromise;
  }

  // Check if we have recent rates in localStorage
  const storedRates = getStoredRates();
  if (storedRates && now - new Date(storedRates.lastUpdated).getTime() < UPDATE_INTERVAL) {
    return storedRates;
  }

  // Fetch new rates
  fetchPromise = (async () => {
    try {
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json() as ExchangeRateApiResponse;
      
      const rates = {
        base: 'USD',
        rates: {
          USD: 1,
          EUR: data.conversion_rates.EUR,
          GBP: data.conversion_rates.GBP,
          TRY: data.conversion_rates.TRY,
          JPY: data.conversion_rates.JPY,
          CNY: data.conversion_rates.CNY
        },
        lastUpdated: new Date().toISOString()
      };

      // Store in localStorage
      storeRates(rates);
      lastFetchTime = now;
      
      return rates;
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
      
      // Try to get rates from localStorage
      const storedRates = getStoredRates();
      if (storedRates) {
        console.log('Using cached exchange rates');
        return storedRates;
      }

      // Fallback to default rates
      console.log('Using default exchange rates');
      return DEFAULT_RATES;
    } finally {
      fetchPromise = null;
    }
  })();

  return fetchPromise;
};

// Subscribe to exchange rate updates
export const subscribeToExchangeRates = (callback: (rates: ExchangeRates) => void) => {
  // Set up periodic updates
  const intervalId = setInterval(() => {
    fetchExchangeRates()
      .then(callback)
      .catch(error => {
        console.error('Error in exchange rate update:', error);
        // Use default rates on error
        callback(DEFAULT_RATES);
      });
  }, UPDATE_INTERVAL);

  // Initial fetch
  fetchExchangeRates()
    .then(callback)
    .catch(error => {
      console.error('Error in initial exchange rate fetch:', error);
      // Use default rates on error
      callback(DEFAULT_RATES);
    });

  // Return cleanup function
  return () => {
    clearInterval(intervalId);
  };
};
