import en from './en';
import tr from './tr';

export interface DashboardTranslations {
  welcome: string;
  overview: {
    title: string;
    newTransaction: string;
    wallets: {
      title: string;
      new: string;
      empty: {
        title: string;
        description: string;
      };
    };
  };
  stats: {
    income: string;
    expenses: string;
    balance: string;
    transactions: string;
    savingsRate: string;
    totalBalance: string;
    totalIncome: string;
    totalExpenses: string;
    changes: {
      positive: string;
      negative: string;
    };
  };
}

export const translations = {
  en,
  tr
} as const;

export const getTranslations = (language: 'en' | 'tr' = 'en'): DashboardTranslations => {
  return translations[language];
};
