export const subscriptionCard = {
  deleteConfirmation: 'Bu aboneliği silmek istediğinizden emin misiniz?',
  nextBilling: 'Sonraki Ödeme',
  paymentMethod: 'Ödeme Yöntemi',
  wallet: 'Cüzdan',
  notSet: 'Ayarlanmadı',
  edit: 'Düzenle',
  delete: 'Sil',
  billingCycle: {
    monthly: 'Aylık',
    yearly: 'Yıllık',
    weekly: 'Haftalık'
  },
  amount: 'Tutar',
  description: 'Açıklama'
};
