import en from './en';
import tr from './tr';

export const translations = { en, tr };

export const getTranslations = (language: string) => {
  switch (language) {
    case 'tr':
      return tr;
    default:
      return en;
  }
};
