import { Budget } from '../types/budget';
import { Transaction } from '../types/transaction';
import { User } from '../types/user';
import { NotificationPreferences } from '../services/notifications';
import { getTransactionsByBudget } from '../services/transactions';
import { updateBudgetSpending } from '../services/budgets';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export const recalculateBudgetSpending = async (budgetId: string): Promise<void> => {
  try {
    // Get current user
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('User must be authenticated');
    }

    // Get complete user data from Firestore
    const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
    const userData = userDoc.data() as User;
    if (!userData) {
      throw new Error('User data not found');
    }

    // Get user's notification preferences
    const notificationPreferences = userData.notificationPreferences || {
      transactions: true,
      upcomingPayments: true,
      invitedMemberActivities: true,
      weeklyReports: true,
      monthlyReports: true,
      emailNotifications: true,
      systemNotifications: true,
      notifyInvitedMembers: false,
    };

    // Fetch all transactions for this budget
    const transactions = await getTransactionsByBudget(budgetId);
    
    // Calculate total spent amount (only for expense transactions)
    const spent = transactions.reduce((sum: number, transaction: Transaction) => {
      // Only count expense transactions
      if (transaction.type === 'expense') {
        return sum + Math.abs(transaction.amount);
      }
      return sum;
    }, 0);
    
    // Get budget data
    const budgetDoc = await getDoc(doc(db, 'budgets', budgetId));
    const budgetData = budgetDoc.data() as Budget;
    
    // Update budget with recalculated spent amount and pass complete user data
    const userWithPreferences = {
      ...userData,
      notificationPreferences
    } as User & { notificationPreferences: NotificationPreferences };

    await updateBudgetSpending(budgetId, spent, userWithPreferences);
  } catch (error) {
    console.error('Failed to recalculate budget spending:', error);
    throw error;
  }
};

export const resetAllBudgetSpendings = async (budgets: Budget[]): Promise<void> => {
  for (const budget of budgets) {
    await recalculateBudgetSpending(budget.id);
  }
};
