import {
  addDoc,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  getDoc,
  orderBy
} from 'firebase/firestore';
import { transactionsCollection, walletsCollection } from '../lib/collections';
import { Transaction } from '../types';
import { recalculateBudgetSpending } from './budgets';
import { getAuth } from 'firebase/auth';

// Helper to remove undefined values from an object
const removeUndefined = (obj: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  );
};

// Helper to verify wallet access
const verifyWalletAccess = async (walletId: string): Promise<boolean> => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (!currentUser) {
    throw new Error('User must be authenticated');
  }

  const walletRef = doc(walletsCollection, walletId);
  const walletSnap = await getDoc(walletRef);
  
  if (!walletSnap.exists()) {
    throw new Error('Wallet not found');
  }

  const walletData = walletSnap.data();
  return walletData.userId === currentUser.uid || 
         (walletData.members && currentUser.uid in walletData.members);
};

// Helper to get wallet currency
const getWalletCurrency = async (walletId: string): Promise<string> => {
  const walletRef = doc(walletsCollection, walletId);
  const walletSnap = await getDoc(walletRef);
  
  if (!walletSnap.exists()) {
    return 'TRY'; // Default to TRY if wallet not found
  }

  return walletSnap.data().currency || 'TRY';
};

export const createTransaction = async (
  userId: string,
  data: Omit<Transaction, 'id'>
): Promise<string> => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  
  if (!currentUser) {
    throw new Error('User must be authenticated');
  }

  // Verify wallet access before creating transaction
  const hasAccess = await verifyWalletAccess(data.walletId);
  if (!hasAccess) {
    throw new Error('You do not have permission to create transactions in this wallet');
  }

  // Ensure transaction has a valid currency
  const currency = data.currency || await getWalletCurrency(data.walletId);

  const transactionData = removeUndefined({
    ...data,
    currency,
    userId: currentUser.uid,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  });

  const docRef = await addDoc(transactionsCollection, transactionData);

  // Update budget spending if this transaction is associated with a budget
  if (data.budgetId) {
    await recalculateBudgetSpending(data.budgetId);
  }

  return docRef.id;
};

export const getUserTransactions = async (userId: string): Promise<Transaction[]> => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  
  if (!currentUser) {
    throw new Error('User must be authenticated');
  }

  // First get all wallets where user is a member
  const walletsQuery = query(
    walletsCollection,
    where('members.' + currentUser.uid, '!=', null)
  );
  const walletsSnapshot = await getDocs(walletsQuery);
  const memberWalletIds = walletsSnapshot.docs.map(doc => doc.id);

  // Then get all wallets owned by user
  const ownedWalletsQuery = query(
    walletsCollection,
    where('userId', '==', currentUser.uid)
  );
  const ownedWalletsSnapshot = await getDocs(ownedWalletsQuery);
  const ownedWalletIds = ownedWalletsSnapshot.docs.map(doc => doc.id);

  // Combine all accessible wallet IDs
  const accessibleWalletIds = [...new Set([...memberWalletIds, ...ownedWalletIds])];

  // If user has no accessible wallets, return empty array
  if (accessibleWalletIds.length === 0) {
    return [];
  }

  // Get transactions from all accessible wallets
  const walletTransactionsQuery = query(
    transactionsCollection,
    where('walletId', 'in', accessibleWalletIds),
    orderBy('date', 'desc')
  );

  const walletTransactionsSnapshot = await getDocs(walletTransactionsQuery);
  
  // Create a map of wallet currencies for efficient lookup
  const walletCurrencies = new Map<string, string>();
  for (const walletId of accessibleWalletIds) {
    walletCurrencies.set(walletId, await getWalletCurrency(walletId));
  }

  const transactions = await Promise.all(
    walletTransactionsSnapshot.docs.map(async doc => {
      const data = doc.data();
      // Ensure each transaction has a valid currency by:
      // 1. Using transaction's own currency if it exists
      // 2. Falling back to wallet's currency from our pre-fetched map
      // 3. Finally defaulting to 'TRY' if neither exists
      const currency = data.currency || walletCurrencies.get(data.walletId) || 'TRY';
      return {
        id: doc.id,
        ...data,
        currency
      } as Transaction;
    })
  );

  return transactions;
};

export const getTransactionsByBudget = async (budgetId: string): Promise<Transaction[]> => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  
  if (!currentUser) {
    throw new Error('User must be authenticated');
  }

  // Get transactions for this budget that the user owns
  const q = query(
    transactionsCollection,
    where('budgetId', '==', budgetId),
    where('userId', '==', currentUser.uid),
    orderBy('date', 'desc')
  );

  const querySnapshot = await getDocs(q);
  const transactions = await Promise.all(
    querySnapshot.docs.map(async doc => {
      const data = doc.data();
      const currency = data.currency || await getWalletCurrency(data.walletId) || 'TRY';
      return {
        id: doc.id,
        ...data,
        currency
      } as Transaction;
    })
  );

  return transactions;
};

export const updateTransaction = async (
  transactionId: string,
  data: Partial<Transaction>
): Promise<void> => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  
  if (!currentUser) {
    throw new Error('User must be authenticated');
  }

  const transactionRef = doc(transactionsCollection, transactionId);
  const transactionSnap = await getDoc(transactionRef);
  
  if (!transactionSnap.exists()) {
    throw new Error('Transaction not found');
  }

  const oldTransaction = transactionSnap.data() as Transaction;
  
  // If wallet is being changed, verify access to new wallet
  if (data.walletId && data.walletId !== oldTransaction.walletId) {
    const hasAccess = await verifyWalletAccess(data.walletId);
    if (!hasAccess) {
      throw new Error('You do not have permission to move transactions to this wallet');
    }
    // Update currency if wallet changes
    if (!data.currency) {
      data.currency = await getWalletCurrency(data.walletId);
    }
  }

  const updateData = removeUndefined({
    ...data,
    currency: data.currency || oldTransaction.currency || 'TRY',
    updatedAt: new Date().toISOString()
  });

  await updateDoc(transactionRef, updateData);

  // Update budget spending if this transaction is associated with a budget
  // or if the budget association has changed
  if (oldTransaction.budgetId) {
    await recalculateBudgetSpending(oldTransaction.budgetId);
  }
  if (data.budgetId && data.budgetId !== oldTransaction.budgetId) {
    await recalculateBudgetSpending(data.budgetId);
  }
};

export const deleteTransaction = async (transactionId: string): Promise<void> => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  
  if (!currentUser) {
    throw new Error('User must be authenticated');
  }

  // Get the transaction's budget ID before deleting
  const transactionRef = doc(transactionsCollection, transactionId);
  const transactionSnap = await getDoc(transactionRef);
  
  if (!transactionSnap.exists()) {
    // If the transaction doesn't exist, we can just return since it's already deleted
    return;
  }

  const transaction = transactionSnap.data() as Transaction;
  
  // Only update budget spending if the transaction exists and has a budgetId
  if (transaction && transaction.budgetId) {
    await recalculateBudgetSpending(transaction.budgetId);
  }

  await deleteDoc(transactionRef);
};
