const translations = {
  errors: {
    loadFailed: 'Failed to load wallets',
    createFailed: 'Failed to create wallet',
    updateFailed: 'Failed to update wallet',
    deleteFailed: 'Failed to delete wallet',
    shareFailed: 'Failed to send invitation',
    removeMemberFailed: 'Failed to remove member'
  },
  success: {
    created: 'Wallet created successfully',
    updated: 'Wallet updated successfully',
    deleted: 'Wallet deleted successfully',
    shared: 'Invitation sent successfully',
    memberRemoved: 'Member removed successfully'
  },
  allWallets: 'All Wallets'
};

export default translations;
