import { BudgetModalTranslations } from './types';

export const translations: BudgetModalTranslations = {
  title: {
    new: 'Yeni Bütçe Ekle',
    edit: 'Bütçeyi Düzenle'
  },
  sections: {
    generalInfo: {
      title: 'Genel Bilgiler',
      fields: {
        name: {
          label: 'Bütçe Adı',
          placeholder: 'Bütçe adını girin'
        },
        amount: {
          label: 'Tutar',
          placeholder: 'Bütçe tutarını girin'
        },
        currency: {
          label: 'Para Birimi'
        }
      }
    },
    filter: {
      title: 'Bütçe Filtresi',
      fields: {
        wallets: {
          label: 'Cüzdanlar',
          allWallets: 'Tüm cüzdanlar'
        },
        categories: {
          label: 'Kategoriler',
          allCategories: 'Tüm kategoriler'
        }
      }
    },
    period: {
      title: 'Bütçe Periyodu',
      fields: {
        recurrence: {
          label: 'Tekrar',
          options: {
            once: 'Bir kez',
            daily: 'Günlük',
            weekly: 'Haftalık',
            biweekly: 'İki haftalık',
            monthly: 'Aylık',
            yearly: 'Yıllık'
          }
        },
        startDate: {
          label: 'Başlangıç tarihi',
          placeholder: 'Başlangıç tarihini seçin'
        },
        endDate: {
          label: 'Bitiş tarihi',
          placeholder: 'Bitiş tarihini seçin'
        }
      }
    }
  },
  buttons: {
    cancel: 'İptal',
    save: 'Kaydet',
    create: 'Oluştur'
  },
  tooltips: {
    name: 'Bütçenize açıklayıcı bir isim verin',
    amount: 'Toplam bütçe tutarını belirleyin',
    wallets: 'Bu bütçeye hangi cüzdanların dahil edileceğini seçin',
    categories: 'Bu bütçede hangi kategorilerin takip edileceğini seçin',
    recurrence: 'Bu bütçenin ne sıklıkla tekrarlanacağını seçin',
    startDate: 'Bu bütçe ne zaman başlamalı?'
  }
};
