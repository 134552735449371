import {
  addDoc,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  orderBy
} from 'firebase/firestore';
import { subscriptionsCollection } from '../lib/collections';
import { Subscription } from '../types/subscription';

export const createSubscription = async (
  userId: string,
  data: Omit<Subscription, 'id'>
): Promise<string> => {
  const subscriptionData = {
    ...data,
    userId,
    status: 'active',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };

  const docRef = await addDoc(subscriptionsCollection, subscriptionData);
  return docRef.id;
};

export const getUserSubscriptions = async (userId: string): Promise<Subscription[]> => {
  const q = query(
    subscriptionsCollection,
    where('userId', '==', userId),
    orderBy('nextBillingDate', 'asc')
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as Subscription));
};

export const updateSubscription = async (
  subscriptionId: string,
  data: Partial<Subscription>
): Promise<void> => {
  const subscriptionRef = doc(subscriptionsCollection, subscriptionId);
  await updateDoc(subscriptionRef, {
    ...data,
    updatedAt: new Date().toISOString()
  });
};

export const deleteSubscription = async (subscriptionId: string): Promise<void> => {
  await deleteDoc(doc(subscriptionsCollection, subscriptionId));
};

export const getUpcomingSubscriptions = async (userId: string): Promise<Subscription[]> => {
  const today = new Date();
  const thirtyDaysFromNow = new Date();
  thirtyDaysFromNow.setDate(today.getDate() + 30);

  const q = query(
    subscriptionsCollection,
    where('userId', '==', userId),
    where('status', '==', 'active'),
    where('nextBillingDate', '>=', today.toISOString()),
    where('nextBillingDate', '<=', thirtyDaysFromNow.toISOString()),
    orderBy('nextBillingDate', 'asc')
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as Subscription));
};