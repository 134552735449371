import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from '../types/user';
import { 
  NotificationPreferences, 
  defaultNotificationPreferences,
  updateNotificationPreferences
} from '../services/notifications';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

type NotificationType = 'success' | 'error' | 'info' | 'warning';

interface NotificationContextType {
  preferences: NotificationPreferences;
  updatePreferences: (newPreferences: Partial<NotificationPreferences>) => Promise<void>;
  showNotification: (type: NotificationType, message: string) => void;
  isLoading: boolean;
  error: string | null;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within NotificationProvider');
  }
  return context;
};

interface NotificationProviderProps {
  children: React.ReactNode;
  user: User | null;
  language: 'en' | 'tr';
}

interface ToastNotification {
  id: string;
  type: NotificationType;
  message: string;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children, user, language }) => {
  const [preferences, setPreferences] = useState<NotificationPreferences>(defaultNotificationPreferences);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<ToastNotification[]>([]);

  useEffect(() => {
    if (!user) {
      setPreferences(defaultNotificationPreferences);
      setIsLoading(false);
      return;
    }

    const userRef = doc(db, 'users', user.id);
    const unsubscribe = onSnapshot(userRef, 
      (doc) => {
        const userData = doc.data();
        if (userData?.notificationPreferences) {
          setPreferences({
            ...defaultNotificationPreferences,
            ...userData.notificationPreferences
          });
        } else {
          setPreferences(defaultNotificationPreferences);
        }
        setIsLoading(false);
        setError(null);
      },
      (error) => {
        console.error('Error fetching notification preferences:', error);
        setError(language === 'tr' 
          ? 'Bildirim tercihleriniz yüklenirken bir hata oluştu.' 
          : 'Error loading notification preferences.');
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user, language]);

  const handleUpdatePreferences = async (newPreferences: Partial<NotificationPreferences>) => {
    if (!user) {
      throw new Error(language === 'tr'
        ? 'Bildirim tercihlerini güncellemek için giriş yapmalısınız.'
        : 'You must be logged in to update notification preferences.');
    }

    try {
      // Create a complete preferences object by merging existing preferences with updates
      const updatedPreferences = {
        ...defaultNotificationPreferences,  // Start with defaults
        ...preferences,                     // Apply current preferences
        ...newPreferences                   // Apply new changes
      };
      
      // Update Firestore with the complete preferences object
      await updateNotificationPreferences(user.id, updatedPreferences);
      
      // Update local state
      setPreferences(updatedPreferences);
      setError(null);

      // Show success notification
      showNotification('success', language === 'tr' 
        ? 'Bildirim tercihleri güncellendi'
        : 'Notification preferences updated');
    } catch (error) {
      console.error('Error updating notification preferences:', error);
      setError(language === 'tr'
        ? 'Bildirim tercihleri güncellenirken bir hata oluştu.'
        : 'Error updating notification preferences.');
      
      // Show error notification
      showNotification('error', language === 'tr'
        ? 'Bildirim tercihleri güncellenirken bir hata oluştu'
        : 'Failed to update notification preferences');
      
      throw error;
    }
  };

  const showNotification = (type: NotificationType, message: string) => {
    const id = Math.random().toString(36).substr(2, 9);
    const notification: ToastNotification = { id, type, message };
    
    setNotifications(current => [...current, notification]);

    // Remove notification after 3 seconds
    setTimeout(() => {
      setNotifications(current => current.filter(n => n.id !== id));
    }, 3000);
  };

  return (
    <NotificationContext.Provider
      value={{
        preferences,
        updatePreferences: handleUpdatePreferences,
        showNotification,
        isLoading,
        error
      }}
    >
      {children}
      {/* Render notifications */}
      <div className="fixed bottom-4 right-4 z-50 space-y-2">
        {notifications.map(notification => (
          <div
            key={notification.id}
            className={`p-4 rounded-lg shadow-lg text-white ${
              notification.type === 'success' ? 'bg-green-500' :
              notification.type === 'error' ? 'bg-red-500' :
              notification.type === 'warning' ? 'bg-yellow-500' :
              'bg-blue-500'
            }`}
          >
            {notification.message}
          </div>
        ))}
      </div>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
