const translations = {
  title: 'Bütçe Detayları',
  noTransactions: 'İşlem bulunamadı',
  allWallets: 'Tüm Cüzdanlar',
  allCategories: 'Tüm Kategoriler',
  wallets: 'Cüzdanlar',
  categories: 'Kategoriler',
  totalBudget: 'Toplam Bütçe',
  spent: 'Harcanan',
  remaining: 'Kalan',
  overview: 'Genel Bakış',
  transactions: 'İşlemler',
  noCategoriesFound: 'Kategori bulunamadı',
    filters: {
      dateRange: 'Tarih Aralığı',
      category: 'Kategori',
      type: 'İşlem Türü'
    },
    transactionTypes: {
      expense: 'Gider',
      income: 'Gelir'
    },
    budgetStatus: {
      onTrack: 'Yolunda',
      overBudget: 'Bütçe Aşıldı',
      nearingLimit: 'Limite Yaklaşıldı'
    },
    insights: {
      title: 'Günlük Bütçe',
      topCategories: 'En Çok Harcama Yapılan Kategoriler',
      monthlyTrend: 'Aylık Harcama Trendi'
    },
  actions: {
    editBudget: 'Bütçeyi Düzenle',
    deleteBudget: 'Bütçeyi Sil',
    addTransaction: 'İşlem Ekle'
  },
  deleteModal: {
    title: 'Bütçeyi Sil',
    message: 'Bu bütçeyi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
    confirmButton: 'Evet, bütçeyi sil',
    cancelButton: 'Hayır, vazgeç'
  }
};

export default translations;
