export const subscriptionCard = {
  deleteConfirmation: 'Are you sure you want to delete this subscription?',
  nextBilling: 'Next Billing',
  paymentMethod: 'Payment Method',
  wallet: 'Wallet',
  notSet: 'Not Set',
  edit: 'Edit',
  delete: 'Delete',
  billingCycle: {
    monthly: 'Monthly',
    yearly: 'Yearly',
    weekly: 'Weekly'
  },
  amount: 'Amount',
  description: 'Description'
};
