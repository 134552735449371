import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { useLanguage } from './LanguageContext';
import { onSnapshot, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { ExchangeRates, subscribeToExchangeRates } from '../services/exchangeRates';
import { formatCurrency } from '../utils/formatters';

interface CurrencyContextType {
  defaultCurrency: string;
  formatAmount: (amount: number, fromCurrency?: string) => string;
  convertAmount: (amount: number, fromCurrency: string, toCurrency: string) => number;
  exchangeRates: ExchangeRates | null;
}

const CurrencyContext = createContext<CurrencyContextType | undefined>(undefined);

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useCurrency must be used within CurrencyProvider');
  }
  return context;
};

export const CurrencyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const [defaultCurrency, setDefaultCurrency] = useState<string>('usd');
  const [exchangeRates, setExchangeRates] = useState<ExchangeRates | null>(null);

  // Subscribe to user document changes for currency updates
  useEffect(() => {
    if (!user?.id) {
      setDefaultCurrency('usd');
      return;
    }

    // Set initial currency from user settings
    if (user.settings?.currency) {
      setDefaultCurrency(user.settings.currency.toLowerCase());
    }

    // Subscribe to user document changes
    const unsubscribe = onSnapshot(
      doc(db, 'users', user.id),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          if (userData?.settings?.currency) {
            console.log('Currency updated:', userData.settings.currency);
            setDefaultCurrency(userData.settings.currency.toLowerCase());
          }
        }
      },
      (error) => {
        console.error('Error subscribing to user document:', error);
      }
    );

    return () => unsubscribe();
  }, [user?.id]);

  // Subscribe to exchange rates
  useEffect(() => {
    const unsubscribe = subscribeToExchangeRates(rates => {
      setExchangeRates(rates);
    });

    return () => unsubscribe();
  }, []);

  const convertAmount = (amount: number, fromCurrency: string, toCurrency: string): number => {
    const from = fromCurrency.toUpperCase();
    const to = toCurrency.toUpperCase();

    if (from === to) return amount;
    if (!exchangeRates) return amount; // Fallback if rates not loaded

    // Get rates for both currencies
    const fromRate = exchangeRates.rates[from];
    const toRate = exchangeRates.rates[to];

    if (!fromRate || !toRate) return amount; // Fallback if rates not available

    // Convert through USD (base currency)
    // First convert to USD by multiplying by USD/FROM rate (which is 1/fromRate)
    const amountInUSD = amount * (1 / fromRate);
    // Then convert from USD to target currency by multiplying by TO/USD rate (which is toRate)
    return amountInUSD * toRate;
  };

  const formatAmount = (amount: number, fromCurrency?: string) => {
    const displayCurrency = defaultCurrency;
    const sourceCurrency = (fromCurrency || defaultCurrency).toLowerCase();
    
    // Convert amount if currencies are different
    const convertedAmount = sourceCurrency !== displayCurrency
      ? convertAmount(amount, sourceCurrency, displayCurrency)
      : amount;

    return formatCurrency(convertedAmount, displayCurrency, language);
  };

  const value = {
    defaultCurrency,
    formatAmount,
    convertAmount,
    exchangeRates
  };

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
};
