export const subscriptionsPage = {
  title: 'Subscriptions',
  subtitle: 'Manage your recurring subscriptions',
  noSubscriptions: 'No subscriptions found',
  addSubscriptionButton: 'Add Subscription',
  upcomingSubscriptions: 'Upcoming Subscriptions',
  pastSubscriptions: 'Past Subscriptions',
  searchPlaceholder: 'Search subscriptions...',
  monthlySpending: 'Monthly Spending',
  filters: {
    status: 'Status',
    platform: 'Platform',
    category: 'Category'
  },
  statuses: {
    active: 'Active',
    cancelled: 'Cancelled',
    expired: 'Expired'
  },
  billingCycle: {
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    yearly: 'Yearly'
  }
};
