import { getAuth } from 'firebase/auth';
import { EmailData, EmailResponse } from '../types/email';

export const sendEmail = async (emailData: EmailData): Promise<void> => {
  try {
    console.log('[Email] Sending email:', {
      template: emailData.template,
      to: emailData.to,
      subject: emailData.subject
    });

    // Get the current user's ID token
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    if (!token) {
      throw new Error('User must be authenticated to send emails');
    }

    const response = await fetch('/api/sendEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(emailData)
    });

    let result: EmailResponse;
    
    // Try to parse the response as JSON
    try {
      const text = await response.text();
      if (!text) {
        throw new Error('Empty response from server');
      }
      result = JSON.parse(text);
    } catch (jsonError) {
      console.error('[Email] Failed to parse response:', jsonError);
      throw new Error(response.status === 200 
        ? 'Invalid JSON response from server'
        : `Server error: ${response.status} ${response.statusText}`);
    }

    // Check for error responses
    if (!response.ok || !result.success) {
      const errorMessage = result.error || result.details || 'Failed to send email';
      console.error('[Email] Server returned error:', {
        status: response.status,
        statusText: response.statusText,
        result
      });
      throw new Error(errorMessage);
    }

    console.log('[Email] Successfully sent email:', {
      template: emailData.template,
      result
    });
  } catch (error) {
    console.error('[Email] Failed to send email:', {
      template: emailData.template,
      error
    });
    throw new Error(error instanceof Error ? error.message : 'Failed to send email');
  }
};
