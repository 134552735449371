import React, { createContext, useContext, useState, useEffect } from 'react';
import { Subscription } from '../types/subscription';
import { useAuth } from './AuthContext';
import {
  createSubscription,
  getUserSubscriptions,
  updateSubscription as updateSubscriptionInDb,
  deleteSubscription as deleteSubscriptionInDb
} from '../services/subscriptions';

interface SubscriptionContextType {
  subscriptions: Subscription[];
  addSubscription: (subscription: Omit<Subscription, 'id'>) => Promise<void>;
  updateSubscription: (id: string, subscription: Partial<Subscription>) => Promise<void>;
  deleteSubscription: (id: string) => Promise<void>;
  getUpcomingSubscriptions: () => Subscription[];
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export const useSubscriptions = () => {
  const context = useContext(SubscriptionContext);
  if (!context) throw new Error('useSubscriptions must be used within SubscriptionProvider');
  return context;
};

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const { user } = useAuth();

  // Fetch subscriptions when the component mounts or user changes
  useEffect(() => {
    const fetchSubscriptions = async () => {
      if (user) {
        try {
          const userSubscriptions = await getUserSubscriptions(user.id);
          setSubscriptions(userSubscriptions);
        } catch (error) {
          console.error('Error fetching subscriptions:', error);
        }
      } else {
        setSubscriptions([]); // Clear subscriptions when user logs out
      }
    };

    fetchSubscriptions();
  }, [user]);

  const addSubscription = async (subscription: Omit<Subscription, 'id'>) => {
    if (!user) return;
    try {
      const newSubscriptionId = await createSubscription(user.id, subscription);
      const newSubscription = {
        ...subscription,
        id: newSubscriptionId
      } as Subscription;
      setSubscriptions(current => [...current, newSubscription]);
    } catch (error) {
      console.error('Error adding subscription:', error);
      throw error;
    }
  };

  const updateSubscription = async (id: string, updates: Partial<Subscription>) => {
    try {
      await updateSubscriptionInDb(id, updates);
      setSubscriptions(current =>
        current.map(subscription =>
          subscription.id === id ? { ...subscription, ...updates } : subscription
        )
      );
    } catch (error) {
      console.error('Error updating subscription:', error);
      throw error;
    }
  };

  const deleteSubscription = async (id: string) => {
    try {
      await deleteSubscriptionInDb(id);
      setSubscriptions(current => current.filter(subscription => subscription.id !== id));
    } catch (error) {
      console.error('Error deleting subscription:', error);
      throw error;
    }
  };

  const getUpcomingSubscriptions = () => {
    return subscriptions
      .sort((a, b) => new Date(a.nextBillingDate).getTime() - new Date(b.nextBillingDate).getTime());
  };

  return (
    <SubscriptionContext.Provider value={{
      subscriptions,
      addSubscription,
      updateSubscription,
      deleteSubscription,
      getUpcomingSubscriptions
    }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
