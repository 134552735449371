export const subscriptionsPage = {
  title: 'Abonelikler',
  subtitle: 'Tekrarlayan aboneliklerinizi yönetin',
  noSubscriptions: 'Abonelik bulunamadı',
  addSubscriptionButton: 'Abonelik Ekle',
  upcomingSubscriptions: 'Yaklaşan Abonelikler',
  pastSubscriptions: 'Geçmiş Abonelikler',
  searchPlaceholder: 'Aboneliklerde ara...',
  monthlySpending: 'Aylık Harcama',
  filters: {
    status: 'Durum',
    platform: 'Platform',
    category: 'Kategori'
  },
  statuses: {
    active: 'Aktif',
    cancelled: 'İptal Edildi',
    expired: 'Süresi Doldu'
  },
  billingCycle: {
    monthly: 'Aylık',
    quarterly: 'Üç Aylık',
    yearly: 'Yıllık'
  }
};
