const translations = {
  home: 'Ana Sayfa',
  dashboard: 'Panel',
  transactions: 'İşlemler',
  wallets: 'Cüzdanlar',
  budgets: 'Bütçeler',
  reports: 'Raporlar',
  categories: 'Kategoriler',
  labels: 'Etiketler',
  settings: 'Ayarlar',
  account: 'Hesap',
  tagline: 'Harcamalarınızı takip edin, paranızı yönetin',
  menu: {
    overview: 'Genel Bakış',
    analytics: 'Analitik',
    history: 'Geçmiş',
    preferences: 'Tercihler',
    help: 'Yardım',
    about: 'Hakkında'
  },
  breadcrumbs: {
    home: 'Ana Sayfa',
    back: 'Geri',
    current: 'Mevcut'
  },
  actions: {
    new: 'Yeni',
    edit: 'Düzenle',
    delete: 'Sil',
    save: 'Kaydet',
    cancel: 'İptal',
    close: 'Kapat'
  }
};

export default translations;
