export const sidebar = {
  appName: 'CostCaddy',
  tagline: 'Track your expenses with ease',
  menu: {
    overview: 'Overview',
    dashboard: 'Dashboard',
    transactions: 'Transactions',
    wallets: 'Wallets',
    budgets: 'Budgets',
    reports: 'Reports',
    creditCards: 'Credit Cards',
    subscriptions: 'Subscriptions',
    settings: 'Settings'
  },
  settings: {
    categories: 'Categories',
    labels: 'Labels',
    notifications: 'Notifications',
    inviteMembers: 'Invite Members',
    email: 'Email Settings',
    account: 'Account'
  },
  admin: {
    title: 'Admin',
    users: 'Users',
    settings: 'Settings',
    subscriptions: 'Subscriptions',
    platforms: 'Platforms',
    categories: 'Categories',
    labels: 'Labels',
    emails: 'Emails',
    languages: 'Languages',
    dashboard: 'Dashboard'
  }
};
