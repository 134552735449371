const translations = {
  title: 'Etiketler',
  subtitle: 'İşlem etiketlerinizi yönetin',
  new: 'Yeni Etiket',
  noLabels: 'Etiket bulunamadı',
  details: {
    usageCount: 'işlem'
  },
  deleteModal: {
    title: 'Etiketi Sil',
    message: 'Bu etiketi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
    confirmButton: 'Evet, etiketi sil',
    cancelButton: 'Hayır, vazgeç'
  }
};

export default translations;
