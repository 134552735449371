export default {
  title: 'Notification Settings',
  description: 'Manage your notification preferences',
  channels: {
    title: 'Notification Channels',
    email: {
      title: 'Email Notifications',
      description: 'Receive notifications via email'
    },
    system: {
      title: 'System Notifications',
      description: 'Receive notifications in your browser'
    }
  },
  preferences: {
    title: 'Notification Types',
    transactions: {
      title: 'Transactions',
      description: 'Get notified about new transactions and updates'
    },
    upcomingPayments: {
      title: 'Upcoming Payments',
      description: 'Receive reminders about upcoming payments'
    },
    invitedMemberActivities: {
      title: 'Invited Member Activities',
      description: 'Get notified about activities from invited members'
    },
    weeklyReports: {
      title: 'Weekly Reports',
      description: 'Receive weekly summary of your financial activities'
    },
    monthlyReports: {
      title: 'Monthly Reports',
      description: 'Receive monthly overview of your finances'
    }
  },
  memberNotifications: {
    title: 'Member Notifications',
    notifyInvitedMembers: {
      title: 'Notify Invited Members',
      description: 'Allow system to send notifications to invited members'
    }
  }
};
