const translations = {
  title: 'Labels',
  subtitle: 'Manage your transaction labels',
  new: 'New Label',
  noLabels: 'No labels found',
  details: {
    usageCount: 'transactions'
  },
  deleteModal: {
    title: 'Delete Label',
    message: 'Are you sure you want to delete this label? This action cannot be undone.',
    confirmButton: 'Yes, delete label',
    cancelButton: 'No, keep label'
  }
};

export default translations;
