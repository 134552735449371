// Map language codes to full locales
export const localeMap: { [key: string]: string } = {
  en: 'en-US',
  tr: 'tr-TR',
  // Add more languages here as needed
};

// Get locale based on language
export const getLocale = (language: string): string => {
  return localeMap[language] || 'en-US';
};

// Format date according to locale
export const formatDate = (date: string | Date, language: string): string => {
  const locale = getLocale(language);
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  
  return dateObj.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

// Format time according to locale
export const formatTime = (date: string | Date, language: string): string => {
  const locale = getLocale(language);
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  
  return dateObj.toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: locale === 'en-US' // Use 12-hour format for US English, 24-hour for others
  });
};

// Format date and time according to locale
export const formatDateTime = (date: string | Date, language: string): string => {
  const locale = getLocale(language);
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  
  return dateObj.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: locale === 'en-US'
  });
};

// Format number according to locale
export const formatNumber = (
  number: number,
  language: string,
  options?: Intl.NumberFormatOptions
): string => {
  const locale = getLocale(language);
  return number.toLocaleString(locale, options);
};

// Format currency according to locale
export const formatCurrency = (
  amount: number,
  currency: string,
  language: string
): string => {
  const locale = getLocale(language);
  try {
    return amount.toLocaleString(locale, {
      style: 'currency',
      currency: currency.toUpperCase(),
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  } catch (error) {
    // Fallback if currency code is invalid
    return `${amount.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })} ${currency.toUpperCase()}`;
  }
};

// Format short date (MM/DD/YYYY or DD/MM/YYYY depending on locale)
export const formatShortDate = (date: string | Date, language: string): string => {
  const locale = getLocale(language);
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  
  return dateObj.toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
};

// Format month and year only
export const formatMonthYear = (date: string | Date, language: string): string => {
  const locale = getLocale(language);
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  
  return dateObj.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long'
  });
};
