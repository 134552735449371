import {
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  query,
  updateDoc,
  where,
  getDocs
} from 'firebase/firestore';
import { budgetsCollection } from '../lib/collections';
import { Budget } from '../types/budget';
import { sendEmail } from './email';
import { getTransactionsByBudget } from './transactions';
import { EmailData } from '../types/email';
import { User } from '../types/user';
import { NotificationPreferences } from './notifications';

export const createBudget = async (userId: string, data: Omit<Budget, 'id'>): Promise<string> => {
  const budgetData = {
    ...data,
    userId,
    spent: 0,
    remaining: data.amount,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };

  const docRef = await addDoc(budgetsCollection, budgetData);
  return docRef.id;
};

export const getUserBudgets = async (userId: string): Promise<Budget[]> => {
  const q = query(
    budgetsCollection,
    where('userId', '==', userId)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as Budget));
};

export const updateBudget = async (budgetId: string, data: Partial<Budget>): Promise<void> => {
  const budgetRef = doc(budgetsCollection, budgetId);
  const budgetSnap = await getDoc(budgetRef);
  const budgetData = budgetSnap.data() as Budget;
  
  // Calculate new spent and remaining values
  const newSpent = data.spent !== undefined ? data.spent : budgetData.spent;
  const newAmount = data.amount !== undefined ? data.amount : budgetData.amount;
  const newRemaining = newAmount - newSpent;

  await updateDoc(budgetRef, {
    ...data,
    spent: newSpent,
    remaining: newRemaining,
    updatedAt: new Date().toISOString()
  });
};

export const deleteBudget = async (budgetId: string): Promise<void> => {
  await deleteDoc(doc(budgetsCollection, budgetId));
};

export const updateBudgetSpending = async (
  budgetId: string, 
  amount: number, 
  user: User & { notificationPreferences: NotificationPreferences }
): Promise<{ isLimitReached: boolean }> => {
  const budgetRef = doc(budgetsCollection, budgetId);
  const budgetSnap = await getDoc(budgetRef);
  const budgetData = budgetSnap.data() as Budget;

  // Calculate new spent and remaining values
  const newSpent = budgetData.spent + amount;
  const newRemaining = budgetData.amount - newSpent;
  const isLimitReached = newRemaining <= 0;

  await updateDoc(budgetRef, {
    spent: newSpent,
    remaining: newRemaining,
    updatedAt: new Date().toISOString()
  });

  // Send email notification if budget limit is reached and email notifications are enabled
  if (isLimitReached && user.notificationPreferences.emailNotifications) {
    try {
      const emailData: EmailData = {
        to: user.email,
        subject: 'Budget Limit Reached',
        template: 'budget-limit-notification',
        data: {
          userId: user.id,
          budgetName: budgetData.name,
          amount: budgetData.amount,
          currency: budgetData.currency,
          spent: newSpent,
          remaining: newRemaining
        }
      };
      await sendEmail(emailData);
    } catch (error) {
      console.error('Failed to send budget limit email:', error);
    }
  }

  return { isLimitReached };
};

// Helper function to check if a budget is near or at its limit
export const checkBudgetLimit = (budget: Budget): {
  isAtLimit: boolean;
  isNearLimit: boolean;
  percentage: number;
} => {
  const percentage = (budget.spent / budget.amount) * 100;
  return {
    isAtLimit: percentage >= 100,
    isNearLimit: percentage >= 90 && percentage < 100,
    percentage
  };
};

// New function to recalculate and update budget spending
export const recalculateBudgetSpending = async (budgetId: string): Promise<void> => {
  const transactions = await getTransactionsByBudget(budgetId);
  const spent = transactions.reduce((sum, transaction) => sum + transaction.amount, 0);
  
  const budgetRef = doc(budgetsCollection, budgetId);
  const budgetSnap = await getDoc(budgetRef);
  const budgetData = budgetSnap.data() as Budget;
  
  await updateDoc(budgetRef, {
    spent,
    remaining: budgetData.amount - spent,
    updatedAt: new Date().toISOString()
  });
};
