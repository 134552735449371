import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export interface NotificationPreferences {
  transactions: boolean;
  upcomingPayments: boolean;
  invitedMemberActivities: boolean;
  weeklyReports: boolean;
  monthlyReports: boolean;
  emailNotifications: boolean;
  systemNotifications: boolean;
  notifyInvitedMembers: boolean;
}

export const defaultNotificationPreferences: NotificationPreferences = {
  transactions: true,
  upcomingPayments: true,
  invitedMemberActivities: true,
  weeklyReports: true,
  monthlyReports: true,
  emailNotifications: true,
  systemNotifications: true,
  notifyInvitedMembers: false,
};

type NotificationType = 'transaction' | 'upcomingPayment' | 'report' | 'memberActivity';
type TransactionAction = 'created' | 'updated' | 'deleted';
type ReportType = 'Weekly' | 'Monthly';

interface BaseNotificationData {
  type: NotificationType;
}

interface TransactionNotificationData extends BaseNotificationData {
  type: 'transaction';
  action: TransactionAction;
  amount: number;
  currency: string;
  walletName: string;
  category: string;
}

interface UpcomingPaymentData extends BaseNotificationData {
  type: 'upcomingPayment';
  amount: number;
  currency: string;
  daysUntilDue: number;
  dueDate: Date;
}

interface ReportData extends BaseNotificationData {
  type: 'report';
  reportType: ReportType;
  dateRange: string;
}

interface MemberActivityData extends BaseNotificationData {
  type: 'memberActivity';
  memberName: string;
  action: string;
  activityType: string;
  walletName: string;
}

type NotificationData = 
  | TransactionNotificationData
  | UpcomingPaymentData
  | ReportData
  | MemberActivityData;

export const updateNotificationPreferences = async (
  userId: string,
  preferences: Partial<NotificationPreferences>
): Promise<void> => {
  const userRef = doc(db, 'users', userId);
  
  // Get current preferences
  const userDoc = await getDoc(userRef);
  const userData = userDoc.data();
  const currentPreferences = userData?.notificationPreferences || defaultNotificationPreferences;

  // Merge current preferences with updates
  const updatedPreferences = {
    ...currentPreferences,
    ...preferences
  };

  // Update the document with merged preferences
  await updateDoc(userRef, {
    'notificationPreferences': updatedPreferences,
    'updatedAt': new Date()
  });
};

export const markNotificationAsRead = async (
  notificationId: string
): Promise<void> => {
  const notificationRef = doc(db, 'notifications', notificationId);
  await updateDoc(notificationRef, {
    read: true,
    updatedAt: new Date(),
  });
};

const transactionTitles = {
  en: {
    created: 'New Transaction',
    updated: 'Transaction Updated',
    deleted: 'Transaction Deleted',
  },
  tr: {
    created: 'Yeni İşlem',
    updated: 'İşlem Güncellendi',
    deleted: 'İşlem Silindi',
  },
} as const;

const reportTitles = {
  en: {
    Weekly: 'Weekly Report Available',
    Monthly: 'Monthly Report Available',
  },
  tr: {
    Weekly: 'Haftalık Rapor Hazır',
    Monthly: 'Aylık Rapor Hazır',
  },
} as const;

export const getNotificationTitle = (
  notification: NotificationData,
  language: 'en' | 'tr' = 'en'
): string => {
  const titles = {
    en: {
      upcomingPayment: 'Upcoming Payment',
      memberActivity: 'Member Activity',
    },
    tr: {
      upcomingPayment: 'Yaklaşan Ödeme',
      memberActivity: 'Üye Aktivitesi',
    },
  } as const;

  switch (notification.type) {
    case 'transaction':
      return transactionTitles[language][notification.action];
    case 'upcomingPayment':
      return titles[language].upcomingPayment;
    case 'report':
      return reportTitles[language][notification.reportType];
    case 'memberActivity':
      return titles[language].memberActivity;
  }
};

const transactionDescriptions = {
  en: {
    created: (data: TransactionNotificationData) => 
      `New transaction of ${data.amount} ${data.currency} in ${data.walletName}`,
    updated: (data: TransactionNotificationData) => 
      `Transaction updated to ${data.amount} ${data.currency} in ${data.walletName}`,
    deleted: (data: TransactionNotificationData) => 
      `Transaction of ${data.amount} ${data.currency} deleted from ${data.walletName}`,
  },
  tr: {
    created: (data: TransactionNotificationData) => 
      `${data.walletName} cüzdanında ${data.amount} ${data.currency} tutarında yeni işlem`,
    updated: (data: TransactionNotificationData) => 
      `${data.walletName} cüzdanındaki işlem ${data.amount} ${data.currency} olarak güncellendi`,
    deleted: (data: TransactionNotificationData) => 
      `${data.walletName} cüzdanından ${data.amount} ${data.currency} tutarındaki işlem silindi`,
  },
} as const;

const reportDescriptions = {
  en: {
    Weekly: (data: ReportData) => `Your weekly financial report for ${data.dateRange} is ready`,
    Monthly: (data: ReportData) => `Your monthly financial report for ${data.dateRange} is ready`,
  },
  tr: {
    Weekly: (data: ReportData) => `${data.dateRange} tarih aralığı için haftalık finansal raporunuz hazır`,
    Monthly: (data: ReportData) => `${data.dateRange} tarih aralığı için aylık finansal raporunuz hazır`,
  },
} as const;

export const getNotificationDescription = (
  notification: NotificationData,
  language: 'en' | 'tr' = 'en'
): string => {
  switch (notification.type) {
    case 'transaction':
      return transactionDescriptions[language][notification.action](notification);
    case 'upcomingPayment':
      return language === 'en'
        ? `Payment of ${notification.amount} ${notification.currency} due in ${notification.daysUntilDue} days`
        : `${notification.daysUntilDue} gün içinde ${notification.amount} ${notification.currency} tutarında ödeme`;
    case 'report':
      return reportDescriptions[language][notification.reportType](notification);
    case 'memberActivity':
      return language === 'en'
        ? `${notification.memberName} ${notification.action} a ${notification.activityType} in ${notification.walletName}`
        : `${notification.memberName}, ${notification.walletName} cüzdanında bir ${notification.activityType} ${notification.action}`;
  }
};
