const translations = {
  title: 'Budget Details',
  noTransactions: 'No transactions found',
  allWallets: 'All Wallets',
  allCategories: 'All Categories',
  wallets: 'Wallets',
  categories: 'Categories',
  totalBudget: 'Total Budget',
  spent: 'Spent',
  remaining: 'Remaining',
  overview: 'Overview',
  transactions: 'Transactions',
  noCategoriesFound: 'No categories found',
  filters: {
    dateRange: 'Date Range',
    category: 'Category',
    type: 'Transaction Type'
  },
  transactionTypes: {
    expense: 'Expense',
    income: 'Income'
  },
  budgetStatus: {
    onTrack: 'On Track',
    overBudget: 'Over Budget',
    nearingLimit: 'Nearing Limit'
  },
  insights: {
    title: 'Daily Budget',
    topCategories: 'Top Spending Categories',
    monthlyTrend: 'Monthly Spending Trend'
  },
  actions: {
    editBudget: 'Edit Budget',
    deleteBudget: 'Delete Budget',
    addTransaction: 'Add Transaction'
  },
  deleteModal: {
    title: 'Delete Budget',
    message: 'Are you sure you want to delete this budget? This action cannot be undone.',
    confirmButton: 'Yes, delete budget',
    cancelButton: 'No, keep budget'
  }
};

export default translations;
