import en from './en';
import tr from './tr';

export interface NavigationTranslations {
  home: string;
  dashboard: string;
  transactions: string;
  wallets: string;
  budgets: string;
  reports: string;
  categories: string;
  labels: string;
  settings: string;
  account: string;
  tagline: string;
  menu: {
    overview: string;
    analytics: string;
    history: string;
    preferences: string;
    help: string;
    about: string;
  };
  breadcrumbs: {
    home: string;
    back: string;
    current: string;
  };
  actions: {
    new: string;
    edit: string;
    delete: string;
    save: string;
    cancel: string;
    close: string;
  };
}

export const translations = {
  en,
  tr
} as const;

export const getTranslations = (language: 'en' | 'tr' = 'en') => {
  return translations[language];
};
